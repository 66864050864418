
import vue from 'vue'
import VueRouter from "vue-router";
vue.use(VueRouter);
/* import Error from '../views/pages/Error.vue' */
import Home from '../views/web/Home.vue'
import Login from '../views/pages/Login.vue'
import Error from '../views/pages/Error.vue'
import Register from '../views/pages/Register.vue'
import ForgotPassword from '../views/pages/ForgotPassword.vue'


const routers = [
  {
    path: '/',
    name: 'Home',
    component: Home,
   
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/cadastro',
    name: 'Register',
    component: Register
  },
  {
    path: '/esqueciSenha',
    name: 'EsqueciSenha',
    component: ForgotPassword
  },
  {
    path: '*',
    component: Error
  }
]

const router = new VueRouter({
  mode: 'history',
  linkActiveClass: 'active',
  scrollBehavior: () => ({
    y: 0
  }),
  routes: routers

})

export default router;