<template>
  <div id="about">
    <div class="cards">
      <div class="card">
        <div class="moeda">
          <div class="icon">
            <img src="../../../assets/icons/BTC.svg" alt="">
          </div>
          <div class="type">
            <div class="nome">BTC</div>
            <div class="abreviacao">BTC</div>
          </div>
        </div>
        <div class="grafico">
          <!-- <canvas id="grafic" style="margin-top: 60px; margin-left: 60px; max-height: 100px;">
            <h3 style="color:white">oiddasdsasadsa</h3>
          </canvas> -->
          <img src="../../../assets/negativo.png" alt="">
        </div>
        <div class="valores">
          <div class="valor">$ {{ dataPrices.USD.last }}</div>
          <div class="taxa">
            <div class="porcentagem">−14,10%</div>
            <div class="hora">24h</div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="moeda">
          <div class="icon">
            <img src="../../../assets/Tether-USDT.png" width="40" alt="">
          </div>
          <div class="type">
            <div class="nome">USDT</div>
            <div class="abreviacao">USDT</div>
          </div>
        </div>
        <div class="grafico">
          
          <img src="../../../assets/positivo.png" alt="">
        </div>
        <div class="valores">
          <div class="valor">$ 1,004</div>
          <div class="taxa">
            <div class="porcentagem">+0,3%</div>
            <div class="hora">24h</div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="moeda">
          <div class="icon">
            <img src="../../../assets/Icon-USDC.png" width="40" alt="">
          </div>
          <div class="type">
            <div class="nome">USDC</div>
            <div class="abreviacao">USDC</div>
          </div>
        </div>
        <div class="grafico">
          <!-- <canvas id="grafic" style="margin-top: 60px; margin-left: 60px; max-height: 100px;">
            <h3 style="color:white">oiddasdsasadsa</h3>
          </canvas> -->
          <img src="../../../assets/negativo.png" alt="">
        </div>
        <div class="valores">
          <div class="valor">$ 1,0</div>
          <div class="taxa">
            <div class="porcentagem">-1,9%</div>
            <div class="hora">24h</div>
          </div>
        </div>
      </div>
    </div>
    <div class="container desktop">
      <div class="content">
        <div class="title">
          About Us
        </div>
        <div class="text" style="word-break: break-all;">
          USDI is a cryptocurrency exchange platform that
  allows users to buy, sell, and trade
  cryptocurrencies such as Bitcoin, Ethereum,
  Litecoin, and many more. The company was
  founded in 2022 in British Virgin Islands. USD
  International offers a user-friendly interface,
  competitive trading fees, and high liquidity, making
  it a popular choice among cryptocurrency traders
  and investors. The platform also provides
  advanced trading tools, security features, and a
  mobile app for convenient trading on-the-go
        </div>
        <div class="btn-create-account">
          <img style="color: #000000 !important;" src="../../../assets/Retângulo 1593.png" alt="">
        </div>
      </div>
      <div class="image">
        <img src="../../../assets/sobre.png" alt="Homem segurando um celular, acessando o painel do nosso site">
      </div>
    </div>
    <div class="container mobile">
      <div class="image">
        <img src="../../../assets/sobre.png" alt="Homem segurando um celular, acessando o painel do nosso site">
      </div>
      <div class="content">
        <div class="title">
          About Us
        </div>
        <div class="text" style="word-break: break-all;">
          USDI is a cryptocurrency exchange platform that
  allows users to buy, sell, and trade
  cryptocurrencies such as Bitcoin, Ethereum,
  Litecoin, and many more. The company was
  founded in 2022 in British Virgin Islands. USD
  International offers a user-friendly interface,
  competitive trading fees, and high liquidity, making
  it a popular choice among cryptocurrency traders
  and investors. The platform also provides
  advanced trading tools, security features, and a
  mobile app for convenient trading on-the-go
        </div>
        <div class="btn-create-account">
          <img style="color: #000000 !important;" src="../../../assets/Retângulo 1593.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      canvas: null,
      context: null,
      isDrawing: false,
      dataPrices: 0,
      startX: 0,
      startY: 0,
      points: [],
    }
  },
  mounted() {
    axios({
      method: 'GET',
      url: 'https://blockchain.info/ticker'
    }).then(res => {
      this.dataPrices = res.data
      console.log(this.dataPrices)
    })

    var canvas = document.getElementById('grafic');
    var ctx = canvas.getContext('2d');
    ctx.moveTo(0, 0)
    ctx.lineTo(this.startX, this.startY)
    ctx.lineTo(40, 30);
    ctx.lineTo(70, 20);
    ctx.lineTo(70, 30);
    ctx.lineTo(100, 10);
    ctx.lineTo(100, 30);
    ctx.lineTo(110, 30);
    ctx.lineTo(130, 40);
    ctx.strokeStyle = '#D94075'
    ctx.stroke();

   
  }

}
</script>

<style scoped>
#about {
  background-image: url(../../../assets/grupo-de-mascara2.svg);
  background-color: #EFEF18;
  opacity: 1;
  width: 100%;
  /* height: 85vh; */
  min-height: 686px;
  transition: 0.5;
}

.cards {
  display: flex;
  margin-left: 30px;
  margin-right: 30px;
  padding-left: auto;
  padding-right: auto;
  transform: translateY(-50px);
  flex-wrap: wrap;
}

.card {
  color: white;
  min-width: 360px;
  height: 100px;
  background: #161616 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 15px #0000001A;
  border-radius: 16px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
}

.moeda {
  display: flex;
}

.icon {
  margin-right: 15px;
}

.nome {
  text-align: left;
  font-size: 18px;
  font-weight: bold;
  font-family: Inter;
  letter-spacing: 0px;
  color: #EFEFEF;
}

.abreviacao {
  text-align: left;
  font-size: 12px;
  font-weight: bold;
  font-family: Inter;
  letter-spacing: 0px;
  color: #9B9B9B;
}

.taxa {
  display: flex;
}

.valor {
  text-align: right;
  font-size: 18px;
  font-weight: bold;
  font-family: Inter;
  letter-spacing: 0px;
  color: #EFEFEF;
}

.porcentagem {
  margin-right: 5px;
  text-align: right;
  font-size: 12px;
  font-weight: bold;
  font-family: Inter;
  letter-spacing: 0px;
  color: #D94075;
}

.hora {
  text-align: right;
  font-size: 12px;
  font-weight: bold;
  font-family: Inter;
  letter-spacing: 0px;
  color: #9B9B9B;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
}

.content {
  /* padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto; */
  max-width: 393px;
}

.title {
  text-align: left;
  font-size: 40px;
  font-weight: bold;
  font-family: Inter;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.text {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  font-family: Inter;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  max-width: 486px;
}

.btn-create-account {
  width: 62px;
  height: 16px;
  border: 1px solid #000000;
  border-radius: 200px;
}


.image img {
  width: 90%;
  display: flex;
  align-items: center;
  margin-top: 50px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}
.mobile{
  display: none;
}
@media (max-width: 440px) {
  .content {
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
  }

    .image img {
      width: 100%;
      padding-left: 0px;
      padding-right: 0px;
      margin-top: -15px;
    }
.title{
  font-size: 28px;
  text-align: center;
}

.mobile {
  display: inline;
}
.desktop {
  display: none;
}
    
}

@media (max-width: 400px) {
  .card {
    padding-top: 15px;
    padding-bottom: 15px;
    min-width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }
}
</style>