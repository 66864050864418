<template>
  <div id="forgotPassword">
    <Header />
    <div class="painel">
      <div class="">
        <div class="content">
          <form>
            <div class="form" v-if="forgot">
              <div class="painel-title">Recover Password</div>
              <div class="painel-text">We will send a recovery code to
                <br>
                the registered email.
              </div>
              <label for="inputs"> Email</label>
              <div class="submit-line">
                <input id="inputs" class="input" placeholder="Enter the registered email" type="text" v-model="email"
                  :state="statusValidEmail" @change="isValidEmail"
                  :class="statusValidEmail == false ? 'invalid-border' : ''" />
                <div v-if="statusValidEmail != true && statusValidEmail != null" style="color: red;">E-mail
                  invalid!</div>
              </div>
              <div class="col-4">
                <div @click='forgotPassword' class="btn-recovery">
                  Recover Password
                </div>
              </div>
            </div>
            <div class="form" v-else>
              <div class="painel-title">Recover Password</div>
              <div class="painel-text">We send a recovery code to the email<div
                  style="display:inline; color: #EFEF18"> {{ email }}</div>, Inform the
                  code in the field below.</div>
              <label for="inputs"> Recovery code</label>
              <div class="submit-line">
                <input id="inputs" class="input" placeholder="Enter the code sent" type="text"
                  v-model="email_code" />
              </div>
              <hr style="
                background: #646464;
                border: 1px solid #646464;
                margin-bottom: 20px;
              " />
              <label for="inputs">New Password</label>
              <div class="submit-line">
                <input id="inputs" class="input" placeholder="Enter your new password" type="text" v-model="password" />
              </div>
              <label for="inputs">New Password</label>
              <div class="submit-line">
                <input id="inputs" class="input" placeholder="Confirm your new password" type="text"
                  v-model="confirmPassword" />
              </div>
              <div class="col-4">
                <div @click='recoveryPassword' class="btn-recovery">
                  Recover
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '../web/Header.vue'
import notify from '../../utils/notify'
export default {
  name: "ForgotPassword",
  components: {
    Header
  },
  data() {
    return {
      statusValidEmail: null,
      forgot: true,
      email: '',
      email_code: '',
      password: '',
      confirmPassword: ''
    }
  },
  methods: {
    isValidEmail() {
      const emailPattern = /^(?=[a-zA-Z0-9@.%+-]{6,254}$)[a-zA-Z0-9.%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
      if (emailPattern.test(this.email) === false) {
        this.statusValidEmail = false;
      } else {
        this.statusValidEmail = null;
      }
    },
    forgotPassword() {
      if (this.email != '' || this.statusValidEmail == true) {
        var data = new FormData();
        data.append("email", this.email);
        this.axios({
          method: "POST",
          url: this.$API_URL + "/Auth/forgotPassword",
          data: data,
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
          .then(res => {
            this.forgot = false
            return res
          })
          .catch(err => {
            this.statusValidEmail = false;
            if (err.response.data.includes("Email invalido")) {
              return notify('error', "Email not registered!");
            }
            else notify('error', "There was a system inconsistency, please try again later");
           

          });
      }
    },
    recoveryPassword() {
      var data = new FormData();
      data.append("email", 'manoelfranciscose@gmail.com');
      data.append("email_code", this.email_code);
      data.append("password", this.password);
      this.axios({
        method: "POST",
        url: this.$API_URL + "/Auth/recoveryPassword",
        data: data,
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
        .then(res => {
          console.log(res);
          /*window.open('http://localhost:8080/', '_blank')*/
        })
        .catch(err => {
          if (err && err.response && err.response.data) {
            if (err.response.data.DATA == 'INVALID_CODE') {
              notify('error', 'Enter the correct code sent to your email');
            }
          }
          else notify('error', "Enter the correct code sent to your email");
        });
    }
  }
}
</script>


<style scoped>
#forgotPassword {
  height: 100%;
}

.painel {
  margin-top: 90px;
  background: #292929 0% 0% no-repeat padding-box !important;
  width: 100%;
  height: 100%;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px !important;
}

form {
  margin-top: 50px;
  background: #161616 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 15px #0000001A;
  border-radius: 16px;
  width: 528px;
  min-height: 508px;
  display: flex;
  justify-content: start;
  padding-top: 50px;
  padding-bottom: 50px;

}

.form {
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: auto;
  margin-right: auto;
}

.painel-title {
  text-align: left;
  font-size: 32px;
  font-family: Inter;
  font-weight: bold;
  letter-spacing: 0px;
  color: #EFEFEF;
}

.painel-text {
  text-align: left;
  font-size: 16px;
  font-family: Inter;
  font-weight: medium;
  letter-spacing: 0px;
  color: #EFEFEF;
  margin-top: 15px;
  margin-bottom: 15px;
}


.input {
  height: 48px;
  background: #292929 0% 0% no-repeat padding-box;
  border: 2px solid #646464;
  border-radius: 8px;
  width: 98% !important;
  margin-bottom: 5px;
}

/* input {
  max-width: 348px !important;
} */

.submit-line {
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 10px;
}

.submit-line input {
  max-width: 586px;
}



#inputs {
  padding-left: 8px;
  min-width: 98%;
  text-align: left;
  color: white;
  font-size: 14px;
  font-weight: 500;
  font-family: Inter;
  letter-spacing: 0px;
  height: 48px;
}

.submit-lente {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  border: none;
  background: transparent;
  outline: none;
  margin-top: 4px;
}

.real {
  margin-right: 4px;
}

.usdi {
  margin-right: 6px;
}

.submit-mascara {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  border: none;
  background: transparent;
  outline: none;
  margin-top: 8px;
  margin-left: 10px;
  font-weight: bold;
  font-size: 16px;
  font-weight: 500;
  font-family: Inter;
  text-align: left;
  color: #9B9B9B;
}

label {
  text-align: left;
  font-family: Inter;
  font-weight: medium;
  font-size: 14px;
  letter-spacing: 0px;
  color: #FFFFFF;
}

.recovery {
  text-align: right;
  margin-bottom: 15px;

}

.recovery a {
  font-family: Inter;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0px;
  color: #EFEF18;
}

.btn-login {
  width: 288px;
  height: 32px;
  border: 2px solid #EFEF18;
  background: #EFEF18;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  font-family: Inter;
  letter-spacing: 0px;
  color: #161616;
  /*   */
  transition: 0.5s;
  margin-right: auto;
  margin-left: auto;
}

.btn-login:hover {
  background: #efef1800;
  color: #EFEF18;
}

.btn-recovery {
  font: normal normal bold 18px/56px Inter;
  margin-top: 15px;
  width: 288px;
  height: 48px;
  border: 2px solid #EFEF18;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  font-family: Inter;
  letter-spacing: 0px;
  color: #EFEF18;
  background: #EFEF18;
  color: #161616;
  /*   */
  transition: 0.5s;
  margin-right: auto;
  margin-left: auto;
}

.invalid-border {
  border: 2px solid red;
}

.btn-recovery:hover {
  background: #161616;
  color: #EFEF18;
}

@media (max-width: 440px) {
  form {
    min-width: 100% !important;
  }
}
</style>


