<template>
  <div id="header">
    <div class="container">
      <div class="nav-bar">
        <div class="logo">
          <img width="102" height="40" src="../../assets/logo.svg" alt="Logo USDI" @click="back">
        </div>
        <div class="navigation">
          <ul id="nav">
            <li @click="addClass('0')" style=""><a href="#home">Home</a>
              <hr id="0" class="select-hr">
            </li>
            <li @click="addClass('1')"><a href="#how-work">How does USDI work</a>
              <hr id="1">
            </li>
            <li @click="addClass('2')"><a href="#why-invest">Why invest in USDI</a>
              <hr id="2">
            </li>
            <li @click="addClass('3')"><a href="#about">About Us</a>
              <hr id="3">
            </li>
            <li @click="addClass('4')"><a href="#simulation">Simulation</a>
              <hr id="4">
            </li>
            <li @click="addClass('5')"><a href="#questions">Questions</a>
              <hr id="5">
            </li>
          </ul>
        </div>
      </div>
      <div @click="login" class="btn-login">
        Login
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Header',
  data() {
    return {
      selectNav: 0,
    }
  },
  methods: {
    back() {
      this.$router.push({ name: "Home" });
    },
    getSection(id) {
      const sectionObj = {
        0:  "#home",
        1:  "#how-work",
        2:  "#why-invest",
        3:  "#about",
        4:  "#simulation",
        5:  "#questions"
      }      
      this.scrollTo(sectionObj[id]) 
    },
    scrollTo(id) {
      document.getElementById(id).scrollIntoView({
        behavior: 'smooth'
      })
    },
    login() {
      this.$router.push({ name: "Login" });
      window.scroll(0,0)
    },
    addClass(el) {
      this.getSection(el)
      this.$router.push({ name: "Home" });
      this.removeClass();
      document.getElementById(`${el}`).classList.add('select-hr')
    },
    removeClass() {
      let lis = document.querySelectorAll('#nav > li > hr');
      lis.forEach((i) => {
        i.classList.remove('select-hr');
      })
    },
    handleScroll(event) {
      if (event.path[1].window.scrollY <= 600) {
        this.removeClass();
        document.getElementById('0').classList.add('select-hr')

      }
      if (event.path[1].window.scrollY >= 501 && event.path[1].window.scrollY <= 742) {
        this.removeClass();
        document.getElementById('1').classList.add('select-hr')

      }
      if (event.path[1].window.scrollY >= 843 && event.path[1].window.scrollY <= 1100) {
        this.removeClass();
        document.getElementById('2').classList.add('select-hr')

      }
      if (event.path[1].window.scrollY >= 1400 && event.path[1].window.scrollY <= 1900) {
        this.removeClass();
        document.getElementById('3').classList.add('select-hr')

      }
      if (event.path[1].window.scrollY >= 2400 && event.path[1].window.scrollY <= 2700) {
        this.removeClass();
        document.getElementById('4').classList.add('select-hr')

      }
      if (event.path[1].window.scrollY > 2750) {
        this.removeClass();
        document.getElementById('5').classList.add('select-hr')

      }
      
    }

  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },

}

</script>

<style scoped>
#header {
  width: 100%;
  height: 92px;
  background: #161616 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 15px #0000001A;
  opacity: 1;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  flex-wrap: wrap;

}

.nav-bar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.btn-login {
  width: 174px;
  height: 32px;
  border: 2px solid #EFEF18;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  font-family: Inter;
  letter-spacing: 0px;
  color: #EFEF18;
  /*   */
  transition: 0.5s;
  margin-right: auto;
  margin-left: auto;
}

.btn-login:hover {
  background: #EFEF18;
  color: #161616;
}

.logo {
  width: 102px;
  height: 40px;
  opacity: 1;
  margin-right: 50px;
  cursor: pointer;
}

.navigation,
ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

li,
a {
  text-decoration: none;
  outline: none;
  list-style-type: none;
  text-align: left;
  font-family: Inter;
  font-size: 18px;
  color: #ebebeb96;
  margin-right: 15px;
  transition: 0.5s;
}

a:hover {
  opacity: 1;
  color: #FFFFFF;
}



.select-hr {
  width: 48px;
  height: 4px;
  background: #EFEF18 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 90%;
  margin-left: -2px;
  margin-bottom: -34px;
  margin-top: 27px;
}

hr.select-hr {
  color: #FFFFFF;
}

@media (max-width: 1080px) {
  .navigation {
    display: none;
  }

  
}

@media (max-width: 440px) {
  #header {
      padding-left: 0px;
      padding-right: 0px;
    }

    .logo {
       margin-right: 0px;
    }
}
</style>