<template>
  <div id="why-invest">
    <div class="container">
      <div class="image">
        <img src="../../../assets/Imagem.png" alt="Homem segurando um celular, acessando o painel do nosso site">
      </div>
      <div class="content">
        <div class="title">
          Why invest in USDI?
        </div>
        <div class="text" style="word-break: break-all;">
          <!--USDI is a Stablecoin, that is, a backed asset, which in the case of USDI is an asset with
          ballast in dollars. For every coin issued there is a dollar equivalent in the cash register. That
          provides investor with security, as it will not be caught by market fluctuations
          International. Another positive point is that USDI does not suffer from the effects of inflation. Soon,
          this asset guarantees the purchasing power of money. Finally, the last reason we highlight
          is the great potential for appreciation of the USDI, as it accompanies the appreciation of the dollar against
          to the real.-->
          Looking to trade cryptocurrencies? Look no further
  than our cutting-edge crypto exchange! With
  lightning-fast transaction speeds, top-notch
  security features, and a user-friendly interface, our
  platform makes it easy to buy, sell, and trade your
  favorite digital assets. Plus, with 24/7 customer
  support and a team of experienced traders on hand
  to help you every step of the way, you can be sure
  you're getting the best possible service. Don't wait –
  sign up today and start trading like a pro!
        </div>
        <h1 class="retangulo">
        </h1>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'PorqueInvestir'

}
</script>

<style scoped>
#why-invest {
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 100%;
  min-height: 500px;
  margin-bottom: 100px;
}

img {
  width: 100%;
}

.retangulo {
  width: 62px;
  height: 16px;
  border: 1px solid #000000;
  border-radius: 200px;
  text-align: center;
  margin-left: auto;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
}

/* .content {
  max-width: 569px;
  margin-top: 100px;
} */

.title {
  text-align: left;
  font-size: 23px !important;
  font-weight: bold;
  font-family: Inter;
  letter-spacing: 0px;
  color: #000000;
}

.text {
  max-width: 400px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: left;
  font-size: 16px;
  font-family: Inter;
  font-weight: 500;
  letter-spacing: 0px;
  color: #333333;
}

.retangulo-group {
  min-width: 465px;
  display: flex;
  justify-content: space-between;
  width: 62px;
  height: 16px;
}

.image {
  max-width: 500px;
  display: flex;
  align-items: center;
  /* min-width: 314px; */
}

@media (max-width: 440px) {
  .content {
      padding-left: 15px;
        padding-right: 15px;
        margin-left: auto;
        margin-right: auto;
  }

  .image {
    padding: 30px;
  }

  .title {
    font-size: 24px;
  }

  .text {
    font-size: 14px;
    text-align: left;
  }
    #why-invest{
      margin-bottom: 60px;
    }
}
</style>