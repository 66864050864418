import Vue from 'vue'
import router from './router'
import App from './App.vue'
import Vuelidate from 'vuelidate'
import VueMask from 'v-mask'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueMeta from 'vue-meta'

Vue.use(VueMeta);
Vue.use(VueMask);
Vue.config.productionTip = false
Vue.use(Vuelidate);
Vue.use(VueAxios, axios)


/* Vue.prototype.$recaptcha = function (data) {
  return new Promise(function (resolve, reject) {
    resolve("RECAPTCHA_ON_LOCALHOST");
  })
} */

Vue.prototype.$API_URL = 'https://api.usdi.com.br/index.php?/v1'

new Vue({  
  router,
  render: h => h(App),
}).$mount('#app')
