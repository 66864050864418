<template>
  <div id="questions">
    <div class="container">
      <div class="content-retangulo">
        <h1 class="retangulo"></h1>
      </div>
      <div style="text-align: center;" class="title">Got any questions?</div>
      <div class="row desktop">
        <div class="questions">
          <div id="00" class="question question-active" @click="addClass('00')">
            <div class="text text-active">How do I create an account?</div>
            <i>
              <span class="material-icons">arrow_forward_ios</span>
            </i>
          </div>
          <div id="01" class="question" @click="addClass('01')">
            <div class="text">How to make a deposit?</div>
            <i>
              <span class="material-icons">arrow_forward_ios</span>
            </i>
          </div>
          <div id="02" class="question" @click="addClass('02')">
            <div class="text">How to make a withdrawal?</div>
            <i>
              <span class="material-icons">arrow_forward_ios</span>
            </i>
          </div>
          <div id="03" class="question" @click="addClass('03')">
            <div class="text">What is a stablecoin?</div>
            <i>
              <span class="material-icons">arrow_forward_ios</span>
            </i>
          </div>
          <div id="04" class="question" @click="addClass('04')">
            <div class="text">What is blockchain?</div>
            <i>
              <span class="material-icons">arrow_forward_ios</span>
            </i>
          </div>
          <div id="05" class="question" @click="addClass('05')">
            <div class="text">Is USDI safe?</div>
            <i>
              <span class="material-icons">arrow_forward_ios</span>
              <!-- <img src="../../../assets/chevron-right.svg" alt=""> -->
            </i>
          </div>
        </div>
        <div class="respostas" style="word-break: break-all;">
          <div v-if="answerOpen == '00'">
            <div class="info">
              Click the button below to create your account quickly and intuitively and enjoy a platform
              completely
              simple and practical.
            </div>
            <div class="info-btn">
              <div class="btn-create-account">
                <a href="">Start now</a>
              </div>
            </div>
          </div>
          <div v-if="answerOpen == '01'">
            The first step to start trading on USDI is to make a deposit into your account, which can be in dollar
            or in
            crypto assets. We have prepared a step-by-step guide on how to make deposits on our platform in a simple way.
            and practice,
            not causing any complications.
          </div>
          <div v-if="answerOpen == '02'">
           Through the “Withdraw” menu you can quickly and easily redeem your assets.
          </div>
          <div v-if="answerOpen == '03'">
            Stablecoins, are cryptocurrencies pegged to some stable asset or basket of assets, of in order to control volatility. A stablecoin can be pegged to a cryptocurrency, a fiat currency, to metals precious, such as gold and silver, or commodities, such as oil.
          </div>
          <div v-if="answerOpen == '04'">
            Blockchain is an immutable, shared ledger that facilitates the process of recording transactions and
            Control of
            assets in a business network. An asset can be tangible (a house, a car, cash, land) or
            intangible
            (intellectual property, patents, copyrights, branding). Virtually any item of value can
            to be
            controlled and traded on a blockchain network, which reduces risks and costs for all parties
            involved.
          </div>
          <div v-if="answerOpen == '05'">
           USDI is a trusted cryptocurrency exchange with a strong focus on security. We take the protection of our users' assets very seriously and employ industry-leading security measures to ensure that your funds and personal information are always safe. Our platform is built with advanced security features, such as two-factor authentication, SSL encryption, and cold storage of funds, to prevent unauthorized access and safeguard against potential hacks or thefts. Additionally, we conduct regular security audits and maintain strict compliance with all regulatory requirements to provide our users with the highest level of security and peace of mind. With USDI, you can trade cryptocurrencies with confidence and rest assured that your assets are in safe hands.
          </div>
        </div>
      </div>
      <div class="row mobile">
        <div class="questions">
          <div>
            <div id="06" class="question" @click="addClass('06')">
              <div class="text">How do I create an account?</div>
              <i>
                <span class="material-icons">arrow_forward_ios</span>
              </i>
            </div>
            <div class="answer" v-if="answerOpen == '06'">
              <div class="info" style="word-break: break-all;">
                Click the button below to create your account quickly and intuitively and enjoy a platform
                completely
                simple and practical.
              </div>
              <div class="info-btn">
                <div class="btn-create-account">
                  <a href="">Start now</a>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div id="07" class="question" @click="addClass('07')">
              <div class="text">How to make a deposit?</div>
              <i>
                <span class="material-icons">arrow_forward_ios</span>
              </i>
            </div>
            <div class="answer" v-if="answerOpen == '07'" style="word-break: break-all;">
              The first step to start trading on USDI is to make a deposit into your account, which can be in
              real or in
              crypto assets. We have prepared a step-by-step guide on how to make deposits on our platform in a
              simple and practical,
              not causing any complications.
            </div>
          </div>
          <div>
            <div id="08" class="question" @click="addClass('08')">
              <div class="text">How to make a withdrawal?</div>
              <i>
                <span class="material-icons">arrow_forward_ios</span>
              </i>
            </div>
            <div class="answer" v-if="answerOpen == '08'" style="word-break: break-all;">
             Through the “Withdraw” menu you can quickly and easily redeem your assets.
            </div>
          </div>
          <div>
            <div id="09" class="question" @click="addClass('09')">
              <div class="text">What is a stablecoin?</div>
              <i>
                <span class="material-icons">arrow_forward_ios</span>
              </i>
            </div>
            <div class="answer" v-if="answerOpen == '09'" style="word-break: break-all;">
              Stablecoins, are cryptocurrencies pegged to some stable asset or basket of assets, of in order to control volatility. A stablecoin can be pegged to a cryptocurrency, a fiat currency, to metals precious, such as gold and silver, or commodities, such as oil.
            </div>
          </div>
          <div>
            <div id="10" class="question" @click="addClass('10')">
              <div class="text">What is blockchain?</div>
              <i>
                <span class="material-icons">arrow_forward_ios</span>
              </i>
            </div>
            <div class="answer" v-if="answerOpen == '10'" style="word-break: break-all;">
              Blockchain is an immutable, shared ledger that facilitates the process of recording transactions and
              of control of
              assets in a business network. An asset can be tangible (a house, a car, cash, land) or
              intangible
              (intellectual property, patents, copyrights, branding). Virtually any item of value can
              to be
              controlled and traded on a blockchain network, which reduces the risks and costs of all
              related parties.
            </div>
          </div>
          <div>
            <div id="11" class="question" @click="addClass('11')">
              <div class="text">Why invest in USDI?</div>
              <i>
                <span class="material-icons">arrow_forward_ios</span>
                <!-- <img src="../../../assets/chevron-right.svg" alt=""> -->
              </i>
            </div>
            <div class="answer" v-if="answerOpen == '11'" style="word-break: break-all;">
              We at USDI are an exchange and we operate in the Cryptoasset Market, establishing a solid relationship of trust and partnership with our customers. Our main objective is to offer a safe and quality service.

    In order to serve our customers well, we rely on three pillars: Agility, Security and Liquidity.

    We offer an efficient platform for your operations that will transform your relationship with Cryptocurrencies. Buying or selling is fast and your operation is completed in a few moments.

    We care for the safety of users and their operations, focusing on constant investments in technology and infrastructure, seeking to offer peace of mind to our customers.
            
            </div>
          </div>
        </div>
        <!--<div class="respostas">
          <div>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
            magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
            consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur.
          </div>
        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Duvidas',
  data() {
    return {
      answerOpen: '00'
    }
  },
  methods: {
    getAnswer(question) {
      return this.answerOpen = question
    },
    addClass(el) {
      this.getAnswer(el)
      this.removeAllClasses();
      document.getElementById(`${el}`).classList.add('question-active')
      document.getElementById(`${el}`).classList.add('question-active')
    },
    removeAllClasses() {
      let questions = document.querySelectorAll('.question');
      questions.forEach((i) => {
        i.classList.remove('question-active');
      })
    },

  }
}
</script>

<style scoped>
#questions {
  min-height: 650px;
  transition: 0.5;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  color: #FFFFFF;

  display: inline-block;
  line-height: 2;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}

.content-retangulo {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 15px;
}

.retangulo {
  width: 62px;
  height: 16px;
  border: 1px solid #000000;
  border-radius: 200px;
  text-align: center;

}

.container {
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}

.title {
  font-size: 40px;
  font-weight: bold;
  font-family: Inter;
  letter-spacing: 0px;
  color: #000000;
}

.row {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 60px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: auto;
  margin-right: auto;
}

.question {
  background: #333333 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 8px #00000029;
  border: 2px solid #646464;
  border-radius: 8px;
  margin-top: 10px;
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  margin-right: 15px;
  cursor: pointer;
  font-weight: bold;
}

.question-active {
  background: #EFEF18 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 8px #00000029;
  border: 2px solid #646464;
  border-radius: 8px;
  margin-top: 10px;
  width: 524px;
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  color: #000000;

}

.text {
  text-align: left;
  font-size: 20px;
  font-weight: medium;
  font-family: Inter;
  letter-spacing: 0px;
  color: #FFFFFF;
}

.question-active .text {
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  font-family: Inter;
  letter-spacing: 0px;
  color: #333333;
}

.btn-create-account {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 288px;
  height: 48px;

  background: #EFEF18 0% 0% no-repeat padding-box;
  border-radius: 24px;
  opacity: 1;
  cursor: pointer;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #161616;
  opacity: 1;
}

.btn-create-account:hover {
  text-align: center;
  letter-spacing: 0px;
  color: #EFEF18;
  opacity: 0.5;
}

a {
  text-decoration: none;
  color: black;
}

.info-btn {
  margin-top: 15px;
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: end;
}

.mobile {
  display: none;
}

.question-active span {
  color: #333333;
  font-weight: 700;
}

.questions {
  margin-bottom: 15px;
  font-weight: bold;
}

.respostas {
/*  font: normal normal medium 22px/30px Inter;*/
  margin-top: 10px;
  margin-bottom: 15px;
  text-align: left;
  font-size: 22px;
  font-weight: 500;
  font-family: Inter;
  letter-spacing: 0px;
  color: #000000;
  max-width: 519px;
}

.answer {
  margin-top: 10px;
  margin-bottom: 15px;
  margin-left: 10px;
  text-align: left;
  font-size: 22px;
  font-weight: 500;
  font-family: Inter;
  letter-spacing: 0px;
  color: #000000;
}

i {
  margin-right: 15px;
  font-size: 26px;
}

@media (max-width: 1180px) {
  #questions {
    min-height: 800px;
  }
}

@media (max-width: 600px) {
  .question-active {
    width: 424px;
  }
}

@media (max-width: 500px) {
  .question-active {
    width: 340px;
  }
}

@media (max-width: 440px) {
  #questions {
    min-height: 600px !important;
  }

  .desktop {
    display: none;
  }

  .mobile {
    display: inline;
  }

  .questions {
    padding-left: 20px;
    padding-right: 35px;
  }

  .text {
    font-size: 16px;
  }

  .text,
  .question-active {
    font-size: 16px !important;
  }

  .respostas {
    display: none;
    max-width: 289px;
  }
}

@media (max-width: 400px) {
  .question-active {
    width: 100%
  }

  .question {
    width: 100%
  }

  .row {
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>