<template>
  <div id="simulation">
    <div class="container">
      <div class="image">
        <img src="../../../assets/usdiLogoBaixa.svg" alt="Homem segurando um celular, acessando o painel do nosso site">
      </div>
      <div class="relative">
        <div class="quadrado1"></div>
        <div class="quadrado2"></div>
        <div class="card">
          <div class="title">
            Simulation
          </div>
          <form class="form" style="
      margin-left: 10px;
  ">
    
            <!-- eslint-disable --> 
    <div class="aselect" :data-value="value" :data-list="list">
              
  	    <div class="selector" @click="toggle()">
  	        <div class="label">
  				    <div class="contentIconLabel">
                <span>
                  <img src="../../../assets/icons/BTC.svg" width="30" alt="" v-if="value === 'BTC'">
                  <img src="../../../assets/icons/USDC.svg" width="30" alt="" v-if="value === 'USDC'">
                  <img src="../../../assets/icons/USDT.svg" width="30" alt="" v-if="value === 'USDT'">
                  
                </span>
    				   <div style="display: flex; flex-direction: column; margin-left: 5px;">
                <span style="font-size: 14px;color: #ccc!important;">{{ value }}</span>
                 <span style="font-size: 12px;" v-if="value === 'BTC'"> $ {{ dataPrices.USD.last }} </span>
                 <span style="font-size: 12px;" v-if="value === 'USDC'"> $ 1 </span>
                 <span style="font-size: 12px;" v-if="value === 'USDT'"> $ 1 </span>
                
               
               </div>
              </div>
  	        </div>
  			<div class="contador teste" >
    <div class="progress-circle-container">
    <div class="progress-circle progress-5"><span>{{ counter }}</span></div>  
  <!--  <div class="progress-circle progress-2"><span>2</span></div>  
    <div class="progress-circle progress-3"><span>3</span></div>  
    <div class="progress-circle progress-4"><span>4</span></div>  
    <div class="progress-circle progress-5"><span>5</span></div>  -->
    </div>
<!--    <div class="percent">
      <svg>
        <circle cx="105" cy="105" r="100"></circle>
        <circle cx="105" cy="105" r="100" style="--percent: 80"></circle>
      </svg>
      <div class="number">
        <h3>30<span>%</span></h3>
      </div>
    </div>-->
    

        </div>
  			<div class="arrow" :class="{ expanded: visible }"></div>
  	        <div :class="{ hidden: !visible, visible }">
  	            <ul>
  	                <li :class="{ current: item === value }" v-for="(item, index) in list" @click="select(item)" :key="index">
                       <div class="contentIconLabel">
                       
                  <span>
                          <img src="../../../assets/icons/BTC.svg" width="30" alt="" v-if="item === 'BTC'">
                    <img src="../../../assets/icons/USDC.svg" width="30" alt="" v-if="item === 'USDC'">
                    <img src="../../../assets/icons/USDT.svg" width="30" alt="" v-if="item === 'USDT'">
                  
                  </span>
      				   <div style="display: flex; flex-direction: column; margin-left: 5px;">
                  <span style="font-size: 14px;color: #ccc!important;">{{ item }}</span>
                   <span style="font-size: 12px;" v-if="item === 'BTC'"> $ {{ dataPrices.USD.last }} </span>
                   <span style="font-size: 12px;" v-if="item === 'USDC'"> $ 1 </span>
                   <span style="font-size: 12px;" v-if="item === 'USDT'"> $ 1 </span>
                 
                 </div>
                </div>
                    </li>
  	            </ul>
  	        </div>
  	    </div>
  	</div>      
           <!-- <div class="select">
           <select>
             <option value="1" >
              <div style="margin-right: 20px !important;">oi</div>
              <div>oi</div>
             </option>
             <option value="2">No            JS</option>
             <option value="3">Nice!</option>
           </select>
         </div>-->
            <label for="real-value">How much do you want to buy?</label>
    
              <div class="submit-line">
                  <CurrencyInput 
                  id="usdi-value" 
                  class="input" 
                  v-model="real"
                  :options="{ currency: 'USD', currencyDisplay: 'hidden', precision: { 'min': 2, 'max': 5 }, }" 
                  />
           
                  <div class="submit-mascara" >{{ value }}</div>
                  <button class="submit-lente real" type="submit">
                   <!-- <img width="25" src="../../../assets/Icon-Dolar.svg" alt="">-->
                  </button>
                </div>
            <label for="usdi-value">Amount you will pay:</label>   
            <div class="submit-line">
                <CurrencyInput 
                id="real-value" 
                class="input" 
                :value="teste"
                :options="{ currency: 'USD', currencyDisplay: 'hidden', precision: { 'min': 2, 'max': 5 }, }" 
                />
           
                <div class="submit-mascara">$</div>
                <button class="submit-lente real" type="submit">
                 <!-- <img width="25" src="../../../assets/Icon-Dolar.svg" alt="">-->
                </button>
              </div>
              
             <div style="display: flex; justify-content: center;"> <button class="btn-create-account">Buy</button></div>

          </form>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VMoney } from 'v-money'
import CurrencyInput from '../../../components/CurrencyInput.vue'
import axios from 'axios'

export default {
  name: 'Simulador',
  components: { CurrencyInput },

  data() {
    return {
      value: 'BTC',
      list: ["BTC", "USDT", "USDC"],
      dataPrices: 0,
      counter: 5,
      visible: false,
      real: 0.0,
      usdi: 0.0,
      money: {
        decimal: ',',
        thousands: '.',
        precision: 2,
        masked: false
      },
      
    }
  },
  computed: {
    teste() { 
      let res = 0
      if (this.value === 'BTC') {
         res = this.real * 142333       
      }
      if (this.value === 'USDT') {
         res = this.real * 1       
      }
      if (this.value === 'USDC') {
         res = this.real * 1       
      }
      if (this.value === 'USDI') {
         res = this.real * 1       
      }
 /*      res = this.real / 1.0*/
      
      return res.toFixed(2)
    }
  },
  methods: {
    toggle() {
      this.visible = !this.visible;
    },
    select(option) {
      this.value = option;
    },
    timer: function () {
      setInterval(this.tikTok, 1000);
    },
    tikTok: function () {  

      if (this.counter == 0) {
       return this.counter = 5
      } else {
        return    --this.counter;
      }
      
   
    },   
/*    start() {
       setInterval(function () {
        if (this.time > 0) {
          this.time = this.time - 1
        } else {
          this.time = 5
        
        }
      }, 1000)
    }*/
    
  },
/*  watch: {
    counter(newV, old) {
      console.log(newV,'new')
      console.log(old,'old')
    }
   },*/
  mounted() { 
   this.$nextTick(() => {
  /* console.log(this.counter)*/
      this.timer();
    });
/*    function abc() {
      clearInterval(timer)
    }*/
    axios({
      method: 'GET',
      url: 'https://blockchain.info/ticker'
    }).then(res => {
      this.dataPrices = res.data
      console.log(this.dataPrices)
    })
    
  },
  directives: { money: VMoney }
}
</script>

<style scoped>

.btn-create-account {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 288px;
  height: 48px;
  background: #EFEF18 0% 0% no-repeat padding-box;
  border: 2px solid #EFEF18;
  border-radius: 24px;
  opacity: 1;
  cursor: pointer;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #161616;
  opacity: 1;
  margin-bottom: 30px;
}

.btn-create-account:hover {
  background: #efef1800 0% 0% no-repeat padding-box;
  border: 2px solid #EFEF18;
  text-align: center;
  letter-spacing: 0px;
  color: #EFEF18;
  opacity: 1;
}



.contentIconLabel{
  display: flex;
}
.teste .progress-circle span {
  font-size: 1rem;
  color: #8b8b8b;
  position: absolute;
  left: 50%;
  top: 50%;
  display: block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin-left: -10px;
  margin-top: -12px;
  text-align: center;
  border-radius: 50%;
  background: #fff;
  z-index: 1; }



.progress-circle.progress-0:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(90deg, #66b8ff 50%, #ebebeb 50%, #ebebeb); }

.progress-circle.progress-1:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(93.6deg, #66b8ff 50%, #ebebeb 50%, #ebebeb); }

.progress-circle.progress-2:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(97.2deg, #66b8ff 50%, #ebebeb 50%, #ebebeb); }

.progress-circle.progress-3:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(100.8deg, #66b8ff 50%, #ebebeb 50%, #ebebeb); }

.progress-circle.progress-4:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(104.4deg, #66b8ff 50%, #ebebeb 50%, #ebebeb); }

.progress-circle.progress-5:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(108deg, #66b8ff 50%, #ebebeb 50%, #ebebeb); }


.teste {
  display: block;

}

.teste .percent {
  position: relative;
}

.teste svg {
  position: relative;
  width: 210px;
  height: 210px;
  transform: rotate(-90deg);
}

.teste svg circle {
  width: 100%;
  height: 100%;
  fill: none;
  stroke: #f0f0f0;
  stroke-width: 10;
  stroke-linecap: round;
}

.teste svg circle:last-of-type {
  stroke-dasharray: 625px;
  stroke-dashoffset: calc(625px - (625px * var(--percent)) / 100);
  stroke: #3498db; 
}

.teste .number {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.teste .number h3 {
  font-weight: 200;
  font-size: 3.5rem;
}

.teste .number h3 span {
  font-size: 2rem;
}

.teste .title h2 {
  margin: 25px 0 0;
}

.teste:nth-child(1) svg circle:last-of-type {
  stroke: #f39c12;
}

.teste:nth-child(2) svg circle:last-of-type {
  stroke: #2ecc71;
}

h1 {
  color: #f9f9f9;
  text-align: center;
  font-size: 36px;
  line-height: 1;
  font-weight: 300;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-family: "Mogra";
  margin-bottom: 0;
  text-shadow: 3px 4px 0px rgba(0, 0, 0, 0.3);
}

.aselect {
  /*width: 280px;*/
  margin: 20px auto;
  border-radius: 8px;
}
.aselect .selector {
  border: 1px solid gainsboro;

  position: relative;
  border-radius: 8px;
  background: #292929;
  border: 2px solid #646464;
  z-index: 99;
}
.aselect .selector .arrow {
  position: absolute;
  right: 10px;
  top: 40%;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 10px solid #888;
  transform: rotateZ(0deg) translateY(0px);
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.59, 1.39, 0.37, 1.01);
}
.aselect .selector .contador {
  position: absolute;
  right: 50px;
  top: 40%;
  width: 0;
  height: 0;
  background: red;
/*  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 10px solid #888;*/
  /*transform: rotateZ(0deg) translateY(0px);
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.59, 1.39, 0.37, 1.01);*/
}
.aselect .selector .expanded {
  transform: rotateZ(180deg) translateY(2px);
}
.aselect .selector .label {
  display: block;
  padding: 12px;
  font-size: 16px;
  color: #888;
}
.aselect ul {
  width: 100%;
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-size: 16px;
  border: 1px solid gainsboro;
  position: absolute;
  z-index: 199;
  background: #fff;
font-weight: bold;
}
.aselect li {
  padding: 12px;
  color: #666;
 /* z-index: 9999;*/
}
.aselect li:hover {
  font-weight: bold;
  color: black;
  background: #EFEF18;

}
.aselect .current {
  background: #eaeaea;
  font-weight: bold;
}
.aselect .hidden {
  visibility: hidden;
}
.aselect .visible {
  visibility: visible;
}

/* Restante */
#simulation {
  width: 100%;
  min-height: 600px;
  background: #292929 0% 0% no-repeat padding-box;
  display: flex;
  justify-content: center;
}

.input {
  height: 30px;
  background: #292929 0% 0% no-repeat padding-box;
  border: 2px solid #646464;
  border-radius: 8px;
}

/* input {
  max-width: 348px !important;
} */

.submit-line {
  position: relative;
  width: 398px;
  margin-bottom: 30px;
  margin-top: 10px;
}

.submit-line input {
  max-width: 586px;
}

#real-value {

  min-width: 375px;
  height: 48px;
  padding-left: 20px;
  text-align: left;
  color: white;
  font-size: 14px;
  font-weight: 500;
  font-family: Inter;
  letter-spacing: 0px;

}

#usdi-value {

  min-width: 345px;
  height: 48px;
  padding-left: 50px;
  text-align: left;
  color: white;
  font-size: 14px;
  font-weight: 500;
  font-family: Inter;
  letter-spacing: 0px;
}

.submit-lente {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  border: none;
  background: transparent;
  outline: none;
  margin-top: 14px;
}

.real {
  margin-right: 16px;
}

.usdi {
  margin-right: 6px;
}

.submit-mascara {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  border: none;
  background: transparent;
  outline: none;
  color: #646464;
  margin-top: 18px;
  margin-left: 10px;
  font-weight: bold;
  font-size: 14px;
  font-family: Inter;
}

.relative {
  position: relative;
}

.container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.card {
  position: relative;
  z-index: 99;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: start;
  padding-left: 45px;
  padding-right: 45px;
  margin-left: auto;
  margin-right: auto;
  max-width: 422px;
  min-height: 350px;
  background: #161616 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 15px #0000001A;
  border-radius: 16px;
}

.title {
  text-align: left;
  font-weight: bold;
  font-size: 40px;
  font-family: Inter;
  letter-spacing: 0px;
  color: #FFFFFF;
  margin-top: 30px;
}

label {
  text-align: left;
  font-size: 14px;
  font-family: Inter;
  font-weight: 500;
  letter-spacing: 0px;
  color: #FFFFFF;
}

.image {
  /*   width: 10%; */
  margin-right: 150px;
}

.quadrado1 {

  position: absolute;
  width: 257px;
  height: 242px;
  border: 2px solid #EFEF18;
  border-radius: 8px;
  opacity: 1;
  top: 38%;
  right: 60%;
}

.quadrado2 {

  position: absolute;
  width: 257px;
  height: 262px;
  border: 2px solid #EFEF18;
  border-radius: 8px;
  opacity: 1;
  bottom: 30%;
  left: 60%;
}

@media (max-width: 945px) {
  .container {
    display: flex;
    justify-content: center;
  }

  .image {
    /*   width: 10%; */
    margin-right: 10px;
  }
}

@media (max-width: 770px) {
  #simulation {

    min-height: 950px;


  }
}

@media (max-width: 507px) {
  .submit-line {
    position: relative;
    width: 248px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .submit-line input {
    max-width: 200px !important;
  }

  .title {
    text-align: center;
  }

  .card {
    justify-content: center;
  }

  #real-value {
    min-width: 215px;
  }

  #usdi-value {
    min-width: 198px;
  }

  .quadrado1,
  .quadrado2 {
    display: none;
  }
}

@media (max-width: 440px) {
  #simulation {
    min-height: 400px;
  }

  .image {
    display: none;
  }

  .title {
    font-size: 28px;
  }

  .relative {
    padding-left: 30px;
    padding-right: 30px;
    margin-left: auto;
    margin-right: auto;
  }
  .card {
    padding-left: 15px;
      padding-right: 15px;
  }
}
</style>