<template>
  <div id="how-work">
    <div class="container">
      <div class="title">
        How does USDI work?
      </div>

      <div class="switches-container" >
        <input type="radio" id="switchMonthly" name="switchPlan" value="Monthly" checked="checked" @click="switchControlling"/>
        <input type="radio" id="switchYearly" name="switchPlan" value="Yearly" @click="switchControlling"/>
        <label for="switchMonthly">I want to buy</label>
        <label for="switchYearly">I want to sell</label>
        <div class="switch-wrapper">
          <div class="switch">
            <div>I want to buy</div>
            <div>I want to sell</div>
          </div>
        </div>
      </div>
      <div class="cards-group" v-if="buy">
        <div class="cards">

          <div class="card">
            <div class="card-content">
              <div class="icon">
                <img src="../../../assets/person-plus.svg" alt="">
              </div>
              <div class="card-title">
                Sign up for free
              </div>
              <div class="card-text">
                Create your account in seconds and validate your documents.
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-content">
              <div class="icon">
                <img src="../../../assets/buy.png" alt="">
              </div>
              <div class="card-title">
                Make a deposit
              </div>
              <div class="card-text">
                Make your deposits quickly and automatically.
              </div>
            </div>
          </div>

        </div>
        <div class="cards group-card2">
          <div class="card">
            <div class="card-content">
              <div class="icon">
                <img src="../../../assets/dollar-sign.svg" alt="">
              </div>
              <div class="card-title">
                Crypto with ease
              </div>
              <div class="card-text">
                With the USDI it is much more practical to be able to
                buy crypto.
               <!-- With the USDI it is much more practical to be able to
                buy dollars, in addition to being easier to convert to real.-->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="cards-group" v-else>
        <div class="cards">
      
          <div class="card">
            <div class="card-content">
              <div class="icon">
                <img src="../../../assets/buy.png" alt="">
              </div>
              <div class="card-title">
                Withdraw whenever you want
              </div>
              <div class="card-text">
                Make your withdrawals whenever and however you want quickly and without stress.
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-content">
              <div class="icon">
                <img src="../../../assets/sell.png" alt="">
              </div>
              <div class="card-title">
                Buy and sell
              </div>
              <div class="card-text">
                Trade anywhere in the world buying and selling quickly.
              </div>
            </div>
          </div>
      
        </div>
        <div class="cards group-card2">
          <div class="card">
            <div class="card-content">
              <div class="icon">
                <img src="../../../assets/activity.svg" alt="">
              </div>
              <div class="card-title">
                The best
              </div>
              <div class="card-text">
                We have the best assets on the market.
              </div>
            </div>
          </div>
         
        </div>
      </div>
    </div>
    <div class="black-row"></div>
    <div class="white-row"></div>
  </div>
</template>

<script>
export default {
  name: 'ComoFunciona',
  metaInfo: {
    title: "USDI - How does USDI work?",
    meta: [
      { name: 'description', content: 'Find out quickly how our platform works.' },
      { property: 'og:title', content: "USDI - How does USDI work?" },
      { property: 'og:site_name', content: 'USDI International' },
      { property: 'og:type', content: 'website' },
      { name: 'robots', content: 'index,follow' }
    ]
  },
  data() {
    return {
      buy: true
    }
  },
  methods: {
    switchControlling() {
      this.buy = !this.buy
    }
  }
}
</script>

<style scoped>
@font-face {
  font-family: Inter;
  src: url(../../../assets/fontes/Inter-VariableFont_slnt\,wght.ttf)
}

#how-work {
  min-height: 464px;
  margin-bottom: 5%;
}

.container {
  height: 298px;
  background: #292929 0% 0% no-repeat padding-box;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}

.title {
  padding-top: 15px;
  margin-bottom: 15px;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  font-size: 40px;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}


* {
  box-sizing: border-box;
}


@media screen and (min-width: 1024px) {

  html,
  body {
    font-size: 24px;
  }
}

@media screen and (max-width: 1024px) {

  html,
  body {
    font-size: 16px;
  }
}

@media screen and (max-width: 600px) {

  html,
  body {
    font-size: 12px;
  }
}

p {
  margin-top: 2rem;
  font-size: 0.75rem;
  text-align: center;
}

.switches-container {
  font-family: Inter;
  font-weight: bold;
  max-width: 374px;
  position: relative;
  display: flex;
  padding: 0;
  position: relative;
  background: #161616 0% 0% no-repeat padding-box;
  line-height: 3rem;
  border-radius: 3rem;
  margin-left: auto;
  margin-right: auto;
}


.switches-container input {
  visibility: hidden;
  position: absolute;
  top: 0;
}


.switches-container label {
  width: 50%;
  padding: 0;
  margin: 0;
  text-align: center;
  cursor: pointer;
  color: white;
  font-family: Inter;
}

.switch-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
  padding: 0.15rem;
  z-index: 3;
  transition: transform 0.5s cubic-bezier(0.77, 0, 0.175, 1);
  /* transition: transform 1s; */
}

.switch {
  border-radius: 3rem;
  background: #EFEF18 0% 0% no-repeat padding-box;
  height: 100%;
}


.switch div {
  width: 100%;
  text-align: center;
  opacity: 0;
  display: block;
  font-family: Inter;
  font-weight: 900;
  font-size: 18px;
  letter-spacing: 0px;
  color: #000000;
  transition: opacity 0.2s cubic-bezier(0.77, 0, 0.175, 1) 0.125s;
  will-change: opacity;
  position: absolute;
  top: 0;
  left: 0;

}


.switches-container input:nth-of-type(1):checked~.switch-wrapper {
  transform: translateX(0%);
}


.switches-container input:nth-of-type(2):checked~.switch-wrapper {
  transform: translateX(100%);
}


.switches-container input:nth-of-type(1):checked~.switch-wrapper .switch div:nth-of-type(1) {
  opacity: 1;
}


.switches-container input:nth-of-type(2):checked~.switch-wrapper .switch div:nth-of-type(2) {
  opacity: 1;
}

.cards-group {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-right: auto;
    margin-bottom: auto;
    padding-left: 30px;
    padding-right: 30px;
}

.cards {
  display: flex;
  justify-content: center;
  margin-top: 60px;
  flex-wrap: wrap;
}

.card {
  min-width: 300px;
  max-width: 265px;
  height: 265px;
  background-color: white;
  box-shadow: 0px 3px 16px #00000029;
  border-radius: 8px;
  opacity: 1;
  display: flex;
  align-items: center;
  margin-right: 40px;
  margin-bottom: 15px;
  padding-left: 25px;
  padding-right: 25px;
}

/* .card-content {
  width: 254px;
  height: 185px;
  margin-left: auto;
  margin-right: auto;
} */

.card-title {
  text-align: left;
  font-size: 18px;
  font-weight: bold;
  font-family: Inter;
  letter-spacing: 0px;
  color: #333333;

}

.card-text {
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  font-family: Inter;
  letter-spacing: 0px;
  color: #898989;
  margin-top: 15px;

}

.icon {
  background: #FCFCD1 0% 0% no-repeat padding-box;
  border: 2px solid #FCFCD1;
  width: 54px;
  height: 54px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;

}

img {
  opacity: 1;
}

.black-row {
  height: 58px;
  background: #000000 0% 0% no-repeat padding-box;
}

.white-row {}

/* @media (max-width: 1367px) {
  #how-work {
    margin-bottom: 35%;
  }
} */

@media (max-width: 1055px) {
  #how-work {
    margin-bottom: 350px;
  }
}

/* @media (max-width: 750px) {
  #how-work {
    margin-bottom: 950px;
  }
} */

@media (max-width: 600px) {
  #how-work {
    margin-bottom: 950px;
  }
}
@media (max-width: 440px) {
.title {
    font-size: 28px;
  }
  .group-card2{
    margin-top: 0px !important;
  }
}
</style>