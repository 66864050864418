<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>


export default {
  name: 'App',
  components: {

  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');
* {

  padding: 0;
  margin: 0;
  vertical-align: baseline;
  list-style: none;
  border: 0
}

#app {
  max-width: 100% !important;
}
</style>
