<template>
  <div id="home">
    <div class="container desktop">
      <div class="content">
        <div class="title">
          The safest way to Buy, Sell and Exchange Crypto.
        </div>
        <div class="text">
          <div class="items-center">
            <svg width="40" height="40" class="svg-inline--fa fa-hand-holding-usd feYBS" aria-hidden="true"
              focusable="false" data-prefix="fak" data-icon="hand-holding-usd" role="img"
              xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
              <path fill="#efef18"
                d="M445 304.2c-7-6.3-15.401-9.101-24.5-9.101-9.1 0-17.5 2.8-24.499 8.402l-42.7 34.299c-1.4 1.4-3.5 1.4-4.9 1.4h-29.402c3.502-6.3 4.9-14 3.502-22.4-2.8-19.6-21.002-33.6-40.601-33.6H181.1c-14.7 0-28 4.9-40.6 14l-26.6 19.6H65.6c-6.3 0-11.2 5.601-11.2 11.2 0 6.3 4.9 11.2 10.5 11.2h56l32.2-25.2c8.4-5.6 17.5-8.4 27.3-8.4h102.9c9.1 0 16.801 6.999 16.801 16.801 0 9.099-7.7 16.799-16.801 16.799h-61.6c-6.3 0-11.2 4.9-11.2 11.2 0 5.599 4.9 11.2 11.2 11.2h126c6.3 0 13.301-2.8 18.2-6.999l43.4-34.301c2.801-2.1 7-3.5 10.499-3.5 3.502 0 7.002 1.4 9.1 3.5 7.701 6.3 7 17.5 0 23.101l-70.699 56c-5.601 4.198-11.901 6.999-18.9 6.999H65.6c-6.3 0-11.2 4.9-11.2 11.2 0 5.599 4.9 11.2 10.5 11.2h274.4c11.901 0 23.099-3.5 32.901-11.2l70.7-56.699c8.399-6.3 13.299-16.801 13.299-28.001 1.4-10.499-3.5-21-11.2-28.7zM246.9 204.1c-4.9-.7-9.1-1.4-26.6-7.7-6.3-2.1-12.6 1.4-14.7 7-1.4 5.6 1.4 11.9 7 14 21 7 25.9 8.4 31.5 9.1v11.9c0 6.3 5.6 11.2 11.9 11.2 5.601 0 10.501-4.9 10.501-11.2v-11.2c21-2.8 35.7-14 39.199-30.8 5.601-32.9-25.9-41.3-46.9-46.9l-5.6-1.4c-23.8-7-26.6-11.2-25.2-19.6 2.1-11.9 19.6-14.7 35.7-11.9 4.9.7 10.499 2.1 18.2 4.2 5.6 2.1 11.901-1.4 14-7 2.1-5.6-1.4-11.9-6.999-14-9.1-2.8-16.102-4.9-21.701-5.6V81.6c0-5.6-5.599-11.2-11.2-11.2-6.3 0-11.9 5.6-11.9 11.2v11.9c-20.3 2.8-35 14-38.5 30.8-5.6 32.2 26.6 41.3 42 45.5l4.9 1.4c26.599 7 32.899 11.2 30.8 21-2.1 11.9-19.6 14.7-36.4 11.9z">
              </path>
            </svg>
            Buy Bitcoin, Tether, Ethereum instantly with credit card,
  debit card or by linking your bank;
          </div>

          <div class="items-center">
            <svg width="24" height="24" class="svg-inline--fa fa-home-heart feYBS" aria-hidden="true" focusable="false"
              data-prefix="fak" data-icon="home-heart" role="img" xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512" data-fa-i2svg="">
              <path
                d="M263.48 78.904c-2.137-1.403-4.987-2.104-7.124-2.104-2.85 0-5.699.701-7.836 2.104L55.474 247.233c-3.562 2.805-4.274 5.61-4.274 8.416 0 6.313 4.986 11.223 11.398 11.223 2.137 0 4.986-.701 7.123-2.805l27.07-23.146V379.09c0 31.562 24.931 56.11 56.275 56.11h204.443c31.345 0 56.277-24.548 56.277-56.11V240.92l28.492 23.147c2.138 2.104 4.987 2.805 7.125 2.805 5.698 0 11.397-4.91 11.397-10.521 0-2.806-1.426-5.611-4.275-8.417L263.48 78.904zm128.935 143.08V379.09c0 18.936-15.673 33.666-33.48 33.666H153.778c-19.233 0-34.193-14.73-34.193-33.666V221.983v-.7l136.77-118.532 136.771 118.531c0 .701-.711.701-.711.701zm-175.238 11.222c-21.37 0-40.604 18.235-40.604 42.081 0 11.223 3.562 21.742 12.11 30.16l60.55 59.617c1.424 2.104 4.274 3.507 6.41 3.507 2.85 0 5.7-1.403 7.123-3.507l60.552-59.618c8.548-8.417 12.82-18.936 12.82-30.159 0-23.846-19.945-41.38-41.316-41.38-10.685 0-24.22 4.208-39.178 18.937-14.247-14.729-27.782-19.638-38.467-19.638zm0 22.443c7.124 0 13.535 3.507 16.384 6.313l22.795 22.444 22.082-22.444c2.85-3.507 9.262-6.313 16.384-6.313 9.262 0 18.522 7.716 18.522 18.937 0 5.611-2.138 10.521-6.41 14.028l-51.29 51.2-50.577-50.498c-4.274-3.507-6.411-8.417-6.411-14.029 0-9.818 7.836-19.638 18.521-19.638z"
                stroke="#EFEF18" fill="#EFEF18">
              </path>
            </svg>
            Trade from home or anywhere in the world;

          </div>

          <div class="items-center">
            <svg width="24" height="24" class="svg-inline--fa fa-chart-up feYBS" aria-hidden="true" focusable="false"
              data-prefix="fak" data-icon="chart-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
              data-fa-i2svg="">
              <path fill="#efef18"
                d="M176.114 308.37l34.721-36.864 42.86 46.226c2.17 2.926 5.426 4.096 9.222 4.096 3.256 0 6.51-1.17 8.681-4.096l60.762-64.95v31.598c0 8.192 5.424 14.042 13.02 14.042 7.053 0 13.02-5.85 13.02-14.042v-65.537c0-7.606-5.967-14.043-13.02-14.043h-60.762c-7.595 0-13.02 6.437-13.02 14.043 0 8.192 5.425 14.044 13.02 14.044h29.297l-50.998 55.59-43.401-46.813c-5.426-5.266-13.563-5.266-18.446 0l-43.401 46.812c-5.425 5.85-5.425 14.628 0 19.894 4.883 5.852 13.563 5.852 18.445 0zM256 51.2c73.142 0 138.972 38.4 175.542 100.571v-31.085c0-7.315 7.316-14.629 14.63-14.629 8.228 0 14.628 7.314 14.628 14.629v62.171c0 8.229-6.4 14.629-14.628 14.629H384c-7.314 0-14.628-6.4-14.628-14.629 0-7.314 7.314-14.628 14.628-14.628h23.772C376.686 114.286 319.086 80.457 256 80.457 160 80.457 80.457 160 80.457 256 80.457 352.914 160 431.542 256 431.542c54.858 0 105.142-23.77 138.972-66.742 4.57-6.4 13.714-8.228 20.114-2.742 6.4 4.57 7.314 13.714 2.742 20.114C378.514 432.458 320 460.8 256 460.8c-112.457 0-204.8-91.428-204.8-204.8 0-112.457 92.343-204.8 204.8-204.8z">
              </path>
            </svg>
            From Zero to USDI in minutes;
          </div>

          <div class="items-center">
            <svg width="34" height="34" class="svg-inline--fa fa-mobile-dollar feYBS" aria-hidden="true"
              focusable="false" data-prefix="fak" data-icon="mobile-dollar" role="img"
              xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
              <path fill="#efef18"
                d="M179.2 51.2h153.6c28.001 0 51.2 23.2 51.2 51.2v307.2c0 28.8-23.199 51.2-51.2 51.2H179.2c-28.8 0-51.2-22.4-51.2-51.2V102.4c0-28 22.4-51.2 51.2-51.2zm153.6 384c13.599 0 25.6-11.2 25.6-25.6V102.4c0-13.6-12.001-25.6-25.6-25.6H179.2c-14.4 0-25.6 12-25.6 25.6v307.2c0 14.4 11.2 25.6 25.6 25.6h153.6zM217.6 384c0-6.4 5.6-12.8 12.8-12.8h51.2c6.4 0 12.8 6.4 12.8 12.8 0 7.199-6.4 12.8-12.8 12.8h-51.2c-7.2 0-12.8-5.601-12.8-12.8zm48-102.7c17.7-2.101 30.3-11.701 33.001-26.4 4.8-27.6-22.2-35.1-40.2-39.9l-4.501-1.5c-20.4-5.7-23.1-9.6-21.9-16.8 1.8-9.9 17.1-12.3 30.9-10.2 3.9.6 9 1.8 15.3 3.9 4.8 1.5 10.2-1.2 11.999-6 1.5-5.1-1.198-10.5-5.998-12.3-7.5-2.4-13.202-3.9-18.301-4.5-.192-.193-.262-.138-.287-.153-.013-.01-.013-.04-.013-.147v-10.5c0-5.1-4.5-9.6-9.6-9.6-5.4 0-9.6 4.5-9.6 9.6V167c-18 2.1-30.6 11.7-33.3 26.1-4.8 27.6 22.5 35.4 35.7 39l4.5 1.2c22.801 6.3 27.9 9.6 26.4 18.3-1.8 9.899-16.8 12.3-31.2 10.201-6-.901-13.2-3.3-19.8-5.701l-3.3-.9c-5.1-1.8-10.5.9-12.3 6-1.5 4.8 1.2 10.201 6 12l3.3 1.201c1.256.402 2.529.82 3.815 1.242 6.39 2.104 13.091 4.308 19.585 5.056.15.15.225.15.3.15.075 0 .15 0 .3.152V291.2c0 5.399 4.2 9.6 9.6 9.6 5.1 0 9.6-4.201 9.6-9.6v-9.9z">
              </path>
            </svg>
           Sign up for free and invest in one of the best
  stablecoins in the world.
          </div>
        </div>
        <div @click="login" class="btn-create-account">
          <a href="">Click here</a>
        </div>
      </div>
      <div class="image">
        <img src="../../../assets/Hero.png" alt="Homem segurando um celular, acessando o painel do nosso site">
      </div>
    </div>
    <div class="container mobile">
      <div class="image-mobile">
        <img src="../../../assets/Hero.png" alt="Homem segurando um celular, acessando o painel do nosso site">
      </div>
      <div class="content">
        <div class="title">
          The safest way to Buy, Sell and Exchange Crypto.
        </div>
        <div class="text">
          <div class="items-center">
            <svg width="40" height="40" class="svg-inline--fa fa-hand-holding-usd feYBS" aria-hidden="true"
              focusable="false" data-prefix="fak" data-icon="hand-holding-usd" role="img"
              xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
              <path fill="#efef18"
                d="M445 304.2c-7-6.3-15.401-9.101-24.5-9.101-9.1 0-17.5 2.8-24.499 8.402l-42.7 34.299c-1.4 1.4-3.5 1.4-4.9 1.4h-29.402c3.502-6.3 4.9-14 3.502-22.4-2.8-19.6-21.002-33.6-40.601-33.6H181.1c-14.7 0-28 4.9-40.6 14l-26.6 19.6H65.6c-6.3 0-11.2 5.601-11.2 11.2 0 6.3 4.9 11.2 10.5 11.2h56l32.2-25.2c8.4-5.6 17.5-8.4 27.3-8.4h102.9c9.1 0 16.801 6.999 16.801 16.801 0 9.099-7.7 16.799-16.801 16.799h-61.6c-6.3 0-11.2 4.9-11.2 11.2 0 5.599 4.9 11.2 11.2 11.2h126c6.3 0 13.301-2.8 18.2-6.999l43.4-34.301c2.801-2.1 7-3.5 10.499-3.5 3.502 0 7.002 1.4 9.1 3.5 7.701 6.3 7 17.5 0 23.101l-70.699 56c-5.601 4.198-11.901 6.999-18.9 6.999H65.6c-6.3 0-11.2 4.9-11.2 11.2 0 5.599 4.9 11.2 10.5 11.2h274.4c11.901 0 23.099-3.5 32.901-11.2l70.7-56.699c8.399-6.3 13.299-16.801 13.299-28.001 1.4-10.499-3.5-21-11.2-28.7zM246.9 204.1c-4.9-.7-9.1-1.4-26.6-7.7-6.3-2.1-12.6 1.4-14.7 7-1.4 5.6 1.4 11.9 7 14 21 7 25.9 8.4 31.5 9.1v11.9c0 6.3 5.6 11.2 11.9 11.2 5.601 0 10.501-4.9 10.501-11.2v-11.2c21-2.8 35.7-14 39.199-30.8 5.601-32.9-25.9-41.3-46.9-46.9l-5.6-1.4c-23.8-7-26.6-11.2-25.2-19.6 2.1-11.9 19.6-14.7 35.7-11.9 4.9.7 10.499 2.1 18.2 4.2 5.6 2.1 11.901-1.4 14-7 2.1-5.6-1.4-11.9-6.999-14-9.1-2.8-16.102-4.9-21.701-5.6V81.6c0-5.6-5.599-11.2-11.2-11.2-6.3 0-11.9 5.6-11.9 11.2v11.9c-20.3 2.8-35 14-38.5 30.8-5.6 32.2 26.6 41.3 42 45.5l4.9 1.4c26.599 7 32.899 11.2 30.8 21-2.1 11.9-19.6 14.7-36.4 11.9z">
              </path>
            </svg>
            Buy Bitcoin, Tether, Ethereum instantly with credit card,
  debit card or by linking your bank;
          </div>

          <div class="items-center">
            <svg width="24" height="24" class="svg-inline--fa fa-home-heart feYBS" aria-hidden="true" focusable="false"
              data-prefix="fak" data-icon="home-heart" role="img" xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512" data-fa-i2svg="">
              <path
                d="M263.48 78.904c-2.137-1.403-4.987-2.104-7.124-2.104-2.85 0-5.699.701-7.836 2.104L55.474 247.233c-3.562 2.805-4.274 5.61-4.274 8.416 0 6.313 4.986 11.223 11.398 11.223 2.137 0 4.986-.701 7.123-2.805l27.07-23.146V379.09c0 31.562 24.931 56.11 56.275 56.11h204.443c31.345 0 56.277-24.548 56.277-56.11V240.92l28.492 23.147c2.138 2.104 4.987 2.805 7.125 2.805 5.698 0 11.397-4.91 11.397-10.521 0-2.806-1.426-5.611-4.275-8.417L263.48 78.904zm128.935 143.08V379.09c0 18.936-15.673 33.666-33.48 33.666H153.778c-19.233 0-34.193-14.73-34.193-33.666V221.983v-.7l136.77-118.532 136.771 118.531c0 .701-.711.701-.711.701zm-175.238 11.222c-21.37 0-40.604 18.235-40.604 42.081 0 11.223 3.562 21.742 12.11 30.16l60.55 59.617c1.424 2.104 4.274 3.507 6.41 3.507 2.85 0 5.7-1.403 7.123-3.507l60.552-59.618c8.548-8.417 12.82-18.936 12.82-30.159 0-23.846-19.945-41.38-41.316-41.38-10.685 0-24.22 4.208-39.178 18.937-14.247-14.729-27.782-19.638-38.467-19.638zm0 22.443c7.124 0 13.535 3.507 16.384 6.313l22.795 22.444 22.082-22.444c2.85-3.507 9.262-6.313 16.384-6.313 9.262 0 18.522 7.716 18.522 18.937 0 5.611-2.138 10.521-6.41 14.028l-51.29 51.2-50.577-50.498c-4.274-3.507-6.411-8.417-6.411-14.029 0-9.818 7.836-19.638 18.521-19.638z"
                stroke="#EFEF18" fill="#EFEF18">
              </path>
            </svg>
            Trade from home or anywhere in the world;
          </div>

          <div class="items-center">
            <svg width="14" height="14" class="svg-inline--fa fa-chart-up feYBS" aria-hidden="true" focusable="false"
              data-prefix="fak" data-icon="chart-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
              data-fa-i2svg="">
              <path fill="#efef18"
                d="M176.114 308.37l34.721-36.864 42.86 46.226c2.17 2.926 5.426 4.096 9.222 4.096 3.256 0 6.51-1.17 8.681-4.096l60.762-64.95v31.598c0 8.192 5.424 14.042 13.02 14.042 7.053 0 13.02-5.85 13.02-14.042v-65.537c0-7.606-5.967-14.043-13.02-14.043h-60.762c-7.595 0-13.02 6.437-13.02 14.043 0 8.192 5.425 14.044 13.02 14.044h29.297l-50.998 55.59-43.401-46.813c-5.426-5.266-13.563-5.266-18.446 0l-43.401 46.812c-5.425 5.85-5.425 14.628 0 19.894 4.883 5.852 13.563 5.852 18.445 0zM256 51.2c73.142 0 138.972 38.4 175.542 100.571v-31.085c0-7.315 7.316-14.629 14.63-14.629 8.228 0 14.628 7.314 14.628 14.629v62.171c0 8.229-6.4 14.629-14.628 14.629H384c-7.314 0-14.628-6.4-14.628-14.629 0-7.314 7.314-14.628 14.628-14.628h23.772C376.686 114.286 319.086 80.457 256 80.457 160 80.457 80.457 160 80.457 256 80.457 352.914 160 431.542 256 431.542c54.858 0 105.142-23.77 138.972-66.742 4.57-6.4 13.714-8.228 20.114-2.742 6.4 4.57 7.314 13.714 2.742 20.114C378.514 432.458 320 460.8 256 460.8c-112.457 0-204.8-91.428-204.8-204.8 0-112.457 92.343-204.8 204.8-204.8z">
              </path>
            </svg>
            From Zero to USDI in minutes;
          </div>

          <div class="items-center">
            <svg width="34" height="34" class="svg-inline--fa fa-mobile-dollar feYBS" aria-hidden="true"
              focusable="false" data-prefix="fak" data-icon="mobile-dollar" role="img"
              xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
              <path fill="#efef18"
                d="M179.2 51.2h153.6c28.001 0 51.2 23.2 51.2 51.2v307.2c0 28.8-23.199 51.2-51.2 51.2H179.2c-28.8 0-51.2-22.4-51.2-51.2V102.4c0-28 22.4-51.2 51.2-51.2zm153.6 384c13.599 0 25.6-11.2 25.6-25.6V102.4c0-13.6-12.001-25.6-25.6-25.6H179.2c-14.4 0-25.6 12-25.6 25.6v307.2c0 14.4 11.2 25.6 25.6 25.6h153.6zM217.6 384c0-6.4 5.6-12.8 12.8-12.8h51.2c6.4 0 12.8 6.4 12.8 12.8 0 7.199-6.4 12.8-12.8 12.8h-51.2c-7.2 0-12.8-5.601-12.8-12.8zm48-102.7c17.7-2.101 30.3-11.701 33.001-26.4 4.8-27.6-22.2-35.1-40.2-39.9l-4.501-1.5c-20.4-5.7-23.1-9.6-21.9-16.8 1.8-9.9 17.1-12.3 30.9-10.2 3.9.6 9 1.8 15.3 3.9 4.8 1.5 10.2-1.2 11.999-6 1.5-5.1-1.198-10.5-5.998-12.3-7.5-2.4-13.202-3.9-18.301-4.5-.192-.193-.262-.138-.287-.153-.013-.01-.013-.04-.013-.147v-10.5c0-5.1-4.5-9.6-9.6-9.6-5.4 0-9.6 4.5-9.6 9.6V167c-18 2.1-30.6 11.7-33.3 26.1-4.8 27.6 22.5 35.4 35.7 39l4.5 1.2c22.801 6.3 27.9 9.6 26.4 18.3-1.8 9.899-16.8 12.3-31.2 10.201-6-.901-13.2-3.3-19.8-5.701l-3.3-.9c-5.1-1.8-10.5.9-12.3 6-1.5 4.8 1.2 10.201 6 12l3.3 1.201c1.256.402 2.529.82 3.815 1.242 6.39 2.104 13.091 4.308 19.585 5.056.15.15.225.15.3.15.075 0 .15 0 .3.152V291.2c0 5.399 4.2 9.6 9.6 9.6 5.1 0 9.6-4.201 9.6-9.6v-9.9z">
              </path>
            </svg>
            Sign up for free and invest in one of the best
  stablecoins in the world.

          </div>
        </div>
        <div @click="login" class="btn-create-account">
          <a href="">Click here</a>
        </div>
      </div>

    </div>
    <h1 class="retangulo">
      <img src="../../../assets/Retângulo 1593.png" alt="">
    </h1>
  </div>
</template>

<script>
export default {
  name: 'Banner',
  methods: {
    login() {
      this.$router.push({ name: "Login" });
    },
  }

}
</script>

<style scoped >
#home {
  background: #292929 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 100%;
  height: 70%;
  margin-top: 92px;
  transition: 0.5;

}

h1 {
  text-align: center;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding-left: 65px;
  padding-right: 65px;
  margin-left: auto;
  margin-right: auto;
  /* margin-bottom: 35px; */
}

.content {
  margin-top: 60px;
  max-width: 515px;
}

.title {
  text-align: left;
  font-size: 36px;
  font-weight: bold;
  font-family: Inter;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}

.text {
  margin-top: 30px;
  margin-bottom: 40px;
  text-align: left;
  font-family: Inter;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0px;
  color: #EBEBEB;
  opacity: 1;
  max-width: 486px;
}

.items-center {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.items-center,
svg {
  margin-right: 15px;
}

/* a {
    font: normal normal bold 18px/56px Inter;
  }*/
.btn-create-account {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 288px;
  height: 48px;
  background: #EFEF18 0% 0% no-repeat padding-box;
  border: 2px solid #EFEF18;
  border-radius: 24px;
  opacity: 1;
  cursor: pointer;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #161616;
  opacity: 1;
}

.btn-create-account:hover {
  background: #efef1800 0% 0% no-repeat padding-box;
  border: 2px solid #EFEF18;
  text-align: center;
  letter-spacing: 0px;
  color: #EFEF18;
  opacity: 1;
}

.btn-span {
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #161616;
  opacity: 1;
}

.btn-span:hover {
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #EFEF18;
  opacity: 1;
}

.image {
  width: 500px;
  display: flex;
  align-items: center;
  margin-top: 40px;
}

.image img {
  width: 100%;
}

.mobile {
  display: none;
}


@media (max-width: 1165px) {
  .title {
    margin-top: 100px;

  }
}

a {
  text-decoration: none;
  color: black;
}


@media (max-width: 440px) {
  #home {
    margin-top: 15px;
  }

  .content {
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
  }

  .image {
    margin-right: 25px !important;
  }

  .desktop {
    display: none;
  }

  .mobile {
    display: inline;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image-mobile {
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
  }

  .content {
    margin-top: -70px;
  }

  .image-mobile img {
    width: 100%;
  }

  .title {
    font-size: 28px;
  }
}
</style>