<template>
  <div id="login">
    <Header />
    <div class="painel">
      <div class="">
        <div class="content">
          <form>
            <div class="form">
              <div class="painel-title">Hello,</div>
              <div class="painel-text">Log in to continue</div>
              <label for="inputs"> Email</label>
              <div class="submit-line">
                <input id="inputs" class="input" placeholder="Enter the registered email" type="text"
                  v-model="form.login" />
              </div>
              <label for="inputs"> Senha</label>
              <div class="submit-line">
                <input id="inputs" class="input" placeholder="Enter your password" type="password"
                  v-model="form.password" />
              </div>
              <div v-show="confirmEmail">
                <label for="inputs"> Confirmation code in Email</label>
                <div class="submit-line">
                  <input id="inputs" class="input" placeholder="Enter the confirmation code" type="text"
                    v-model="form.confirmEmail" />
                </div>
              </div>
              <div v-show="require2FA">
                <label for="inputs">2FA Code</label>
                <div class="submit-line">
                  <input id="inputs" class="input" placeholder="Enter your 2FA" type="text" v-model="form.code" />
                </div>
              </div>
              <p class="recovery" @click.prevent="recovery">
                <a href="">
                  Forgot password
                </a>
              </p>
              <div class="col-4">
                <div @click="login" class="btn-login">
                  Login
                </div>
              </div>
              <div class="col-4">
                <div @click='register' class="btn-cadastro">
                  Register
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '../web/Header.vue'
import notify from '../../utils/notify'
export default {
  name: 'Login',
  metaInfo: {
    title: "USDI - Login",
    meta: [
      { name: 'description', content: "Log in and enjoy the world's largest stablecoin platform" },
      { property: 'og:title', content: "USDI - Login" },
      { property: 'og:site_name', content: 'USDI International' },
      { property: 'og:type', content: 'website' },
      { name: 'robots', content: 'index,follow' }
    ]
  },
  components: {
    Header,
  },
  data() {
    return {
      require2FA: false,
      confirmEmail:false,
      form: {
        login: '',
        password: '',
        code: '',
        confirmEmail:'',
        
      }
    }
  },
  methods: {
    recovery() {
      this.$router.push({ name: "EsqueciSenha" });
    },
    register() {
      this.$router.push({ name: "Register" });
    },
    login() {
      if (this.require2FA) this.login2FA()
      else if (this.confirmEmail) this.loginConfirmEmail()
      else this.loginBase();
    },
    loginBase() {
  
      var data = new FormData();
      data.append("email", this.form.login);
      data.append("password", this.form.password);     
      this.axios({
        method: "POST",        
        url: this.$API_URL + "/Auth/login",
        data: data,
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
        .then(res => {
          console.log(res);
          window.open('http://localhost:8080/', '_blank')        
          
        })
        .catch(err => {
          if (err && err.response && err.response.data) {
            if (err.response.data.DATA == 'REQUIRE_2FA') {
              notify('error', 'Enter google authenticator code')
              this.require2FA = true;
            }
            if (err.response.data.DATA == 'CONFIRM_YOUR_EMAIL') {
              notify('error', 'Confirm your email')
              this.confirmEmail = true;
            }
          }
          
          else notify('error', "INVALID_ERROR");
        });
    },
    login2FA() {
      var data = new FormData();
      data.append("email", this.form.login);
      data.append("password", this.form.password);
      data.append('code', this.form.code);
      this.axios({
        method: "POST",
        url: this.$API_URL + "/Auth/login",
        data: data,
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
        .then(res => {
          alert('logado')
          this.require2FA = false;
          console.log(res)
        })
        .catch(err => {
          console.log(err)
          notify('error', 'Invalid password or 2FA')         
          
          
        });
    },
    loginConfirmEmail() {
      var data = new FormData();
      data.append("email", this.form.login);
      data.append("password", this.form.password);
      data.append('codeEmail', this.form.confirmEmail);
      this.axios({
        method: "POST",
        url: this.$API_URL + "/Auth/login",
        data: data,
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
        .then(res => {
          alert('logado');
          console.log(res)
          this.confirmEmail = false;
        })
        .catch(err => {
          console.log(err)
          notify('error', `Invalid email confirmation code`);          
        });
    }
  }
}
</script>

<style scoped>
#login{
  height: 100%;
}
.painel {
  margin-top: 90px;
  background: #292929 0% 0% no-repeat padding-box !important;
  width: 100%;
  height: 100vh;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
padding-bottom: 20px !important;
}

form {
  margin-top: 50px;
  background: #161616 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 15px #0000001A;
  border-radius: 16px;
  min-width: 528px;
  min-height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form {
  /* padding: 10px; */
}

.painel-title {
  text-align: left;
  font-size: 32px;
  font-family: Inter;
  font-weight: bold;
  letter-spacing: 0px;
  color: #EFEFEF;
}

.painel-text {
  text-align: left;
  font-size: 16px;
  font-family: Inter;
  font-weight: medium;
  letter-spacing: 0px;
  color: #EFEFEF;
  margin-top: 15px;
  margin-bottom: 15px;
}


.input {
  height: 30px;
  background: #292929 0% 0% no-repeat padding-box;
  border: 2px solid #646464;
  border-radius: 8px;
  width: 100% !important;
}

/* input {
  max-width: 348px !important;
} */

.submit-line {
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 10px;
}

.submit-line input {
  max-width: 586px;
}



#inputs {
  padding-left: 8px;
  min-width: 100%;
  text-align: left;
  color: white;
  font-size: 14px;
  font-weight: 500;
  font-family: Inter;
  letter-spacing: 0px;
}

.submit-lente {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  border: none;
  background: transparent;
  outline: none;
  margin-top: 4px;
}

.real {
  margin-right: 4px;
}

.usdi {
  margin-right: 6px;
}

.submit-mascara {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  border: none;
  background: transparent;
  outline: none;
  margin-top: 8px;
  margin-left: 10px;
  font-weight: bold;
  font-size: 16px;
  font-weight: 500;
  font-family: Inter;
  text-align: left;
  color: #9B9B9B;
}

label {
  text-align: left;
  font-family: Inter;
  font-weight: medium;
  font-size: 14px;
  letter-spacing: 0px;
  color: #FFFFFF;
}

.recovery {
  text-align: right;
  margin-bottom: 15px;

}

.recovery a {
  font-family: Inter;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0px;
  color: #EFEF18;
}

.btn-login {
  width: 288px;
  height: 32px;
  border: 2px solid #EFEF18;
  background: #EFEF18;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  font-family: Inter;
  letter-spacing: 0px;
  color: #161616;
  /*   */
  transition: 0.5s;
  margin-right: auto;
  margin-left: auto;
}

.btn-login:hover {
  background: #efef1800;
  color: #EFEF18;
}

.btn-cadastro {
  margin-top: 15px;
  width: 288px;
  height: 32px;
  border: 2px solid #EFEF18;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  font-family: Inter;
  letter-spacing: 0px;
  color: #EFEF18;
  /*   */
  transition: 0.5s;
  margin-right: auto;
  margin-left: auto;
}

.btn-cadastro:hover {
  background: #EFEF18;
  color: #161616;
}

@media (max-width: 440px) {
  form { 
    min-width: 100% !important; 
  }
}
</style>


