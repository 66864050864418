<template>
  <div id="home">
    <Header />
    <Banner />
    <ComoFunciona />
    <PorqueInvestir />
    <Sobre />
    <Simulador />
    <Duvidas />
    <Footer />
  </div>
</template>

<script>
import Header from './Header.vue'
import Banner from './sections/Banner.vue';
import ComoFunciona from './sections/ComoFunciona.vue';
import PorqueInvestir from './sections/PorqueInvestir.vue';
import Sobre from './sections/Sobre.vue';
import Simulador from './sections/Simulador.vue';
import Duvidas from './sections/Duvidas.vue';
import Footer from './Footer.vue'

export default {
  name: 'Home',
  components: {
    Header,
    Banner,
    ComoFunciona,
    PorqueInvestir,
    Sobre,
    Simulador,
    Duvidas,
    Footer,
  }
}
</script>

<style scoped>
#home {
  padding: 0;
}
</style>