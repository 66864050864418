<template>
  <div id="painel">
    <Header />
    <div class="painel">
      <div class="">
        <div class="content">
          <form>
            <div class="form">
              <div class="painel-title">Create an account</div>
              <div class="painel-text">
                Fill out the form below to create your account.
              </div>
              <div class="account-type">
                <div class="sub-title">Account Type</div>
                <div class="radio-group">
                  <div class="custom-radio">
                    <input id="radiobox-1" type="checkbox" @click="selectType('CPF')" v-model="pf" />
                    <label for="radiobox-1">Physical person</label>
                  </div>
                  <div class="custom-radio">
                    <input id="radiobox-2" type="checkbox" @click="selectType('CNPJ')" v-model="pj" />
                    <label for="radiobox-2">Legal person</label>
                  </div>
                </div>      
                
              </div>
              <label for="inputs" v-if="pf == true">Full name</label>
              <label for="inputs" v-else>Company Name</label>
              <div class="submit-line" v-if="pf == true">
                <input
                  style="width: 100%"
                  id="inputs"
                  class="input"
                  placeholder="Enter your full name"
                  type="text"
                  required
                  v-model="form.name"
                />
              </div>
              <div class="submit-line" v-else>
                <input
                  style="width: 100%"
                  id="inputs"
                  class="input"
                  placeholder="Enter company name"
                  type="text"
                  required
                  v-model="form.name"
                />
              </div>
              <div class="input-group">
                <div class="submit-line">
                  <label for="" v-if="pf">CPF</label>
                  <label for="" v-if="pj">CNPJ</label>
                  <input
                  v-if="pf"
                    id="inputs"
                    class="input"
                    placeholder="Enter your CPF"
                    type="text"
                    v-mask="'###.###.###-##'"
                    :state="statusValidDocument" @change="isValidCpfCnpj"
                    :class="statusValidDocument == false ? 'invalid-border': ''"
                    v-model="form.document"
                  />
                  <input
                  v-if="pj"
                    id="inputs"
                    class="input"
                    placeholder="Enter your CPNJ"
                    type="text"
                    v-mask="'##.###.###/####-##'"
                    :state="statusValidDocument" @change="isValidCpfCnpj"
                    :class="statusValidDocument == false ? 'invalid-border': ''"
                    v-model="form.document"
                  />
                  <div v-if="statusValidDocument != true && statusValidDocument != null" style="color: red;">Document
                    invalid!
                  </div>
                </div>
                <div class="submit-line">
                  <label for="" v-if="pf == true">Date of birth</label>
                  <label for="" v-else>Date of establishment</label>
                  <input
                  v-if="pf == true"
                    id="inputs"
                    class="input"
                    placeholder="Date of birth"
                    type="text"
                    v-mask="'##/##/####'"
                    v-model="form.birthday"
                    :state="statusValidbirth" @change="isValidAge"
                    :class="statusValidbirth == false ? 'invalid-border': ''"
                  />
                  <input
                  v-else
                    id="inputs"
                    class="input"
                    placeholder="Date of establishment"
                    type="text"
                    v-mask="'##/##/####'"
                    v-model="form.birthday"
                    :state="statusValidbirth" @change="isValidAge"
                    :class="statusValidbirth == false ? 'invalid-border': ''"
                  />
                  <div v-if="statusValidbirth != true && statusValidbirth != null" style="color: red;">Prohibited or
                  registration
                  <br />
                  under 18 years old!</div>
                </div>
              </div>
              <div class="input-group">
                <div class="submit-line">
                  <label for="">Email</label>
                  <input
                    id="inputs"
                    class="input"
                    placeholder="Enter your email"
                    type="email"
                    v-model="form.email"
                    :state="statusValidEmail" @change="isValidEmail"
                    :class="statusValidEmail == false ? 'invalid-border': ''"
                  />
                  <div v-if="statusValidEmail != true && statusValidEmail != null" style="color: red;">E-mail
                    invalid!</div>
                </div>
                <div class="submit-line">
                  <label for="">Phone</label>
                  <input
                    id="inputs"
                    class="input"
                    placeholder="Enter your phone"
                    v-mask="'## # ####-####'"
                    type="text"
                    v-model="form.phone"
                  />
                </div>
              </div>
              <hr
                style="
                  background: #646464;
                  border: 1px solid #646464;
                  margin-bottom: 20px;
                "
              />
              <label for="inputs">Pawssord</label>
              <div class="submit-line">
                <input
                  style="width: 100%"
                  id="inputs"
                  class="input"
                  placeholder="Create a password"
                  type="password"
                  v-model="form.password"
                />
              </div>
              <label for="inputs">Confirm password</label>
              <div class="submit-line">
                <input
                  style="width: 100%"
                  id="inputs"
                  class="input"
                  placeholder="Create a password"
                  type="password"
                  v-model="form.confirmPassword"
                />
              </div>
              <div class="termos">
                <label for="">
                  <input type="checkbox" v-model="termos" />
                  By registering, I accept the
                  <a class="yello-ancora" href="">terms of use</a>
                  <br />
                  and the
                  <a class="yello-ancora" href="">Privacy Policy</a>.
                </label>
              </div>
              <div class="col-4">
                <div @click="newUser" class="btn-cadastro">Register</div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../web/Header.vue";
import { validateBr } from "js-brasil";
import notify from '../../utils/notify'
export default {
  name: "Cadastro",
  components: {
    Header,
  },
  data() {
    return {
      statusValidEmail: null,
      statusValidDocument: null,
      statusValidCep: null,
      statusValidbirth: null,
      pf: true,
      pj: false,
      termos: false,
      form: {
        type: "CPF",
        name: "",
        document: "",
        birthday: "",
        email: "",
        phone: "",
        password: "",
        confirmPassword: "",
      },
    };
  },
  methods: {
    digitsVal(document) {
      if (validateBr.cpf(document) === false) {
        if (validateBr.cnpj(document) === false) {
          return false;
        }
      } else {
        return true;
      }
    },
    isValidCpfCnpj() {
      if (this.digitsVal(this.form.document) === false) {
        this.statusValidDocument = false;
      } else {
        this.statusValidDocument = null;
      }
    },
    isValidEmail() {
      const emailPattern = /^(?=[a-zA-Z0-9@.%+-]{6,254}$)[a-zA-Z0-9.%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
      if (emailPattern.test(this.form.email) === false) {
        this.statusValidEmail = false;
      } else {
        this.statusValidEmail = null;
      }
    },
    isValidAge() {
      console.log(this.calculateAge())
      if (this.calculateAge() < 18) {

        this.statusValidbirth = false;
      } else {
        this.statusValidbirth = null;
      }
    },
    calculateAge() {
      let data = this.form.birthday.split('-').reverse().join('/');
      var d = new Date,
        ano_atual = d.getFullYear(),
        mes_atual = d.getMonth() + 1,
        dia_atual = d.getDate(),
        split = data.split('/'),
        novadata = split[1] + "/" + split[0] + "/" + split[2],
        data_americana = new Date(novadata),
        vAno = data_americana.getFullYear(),
        vMes = data_americana.getMonth() + 1,
        vDia = data_americana.getDate(),
        ano_aniversario = +vAno,
        mes_aniversario = +vMes,
        dia_aniversario = +vDia,
        quantos_anos = ano_atual - ano_aniversario;
      if (mes_atual < mes_aniversario || mes_atual == mes_aniversario && dia_atual < dia_aniversario) {
        quantos_anos--;
      }
      return quantos_anos < 0 ? 0 : quantos_anos;
    },
    selectType(type) {      
      if (type === "CPF") {
        this.pj = false;
        this.pf = !this.pf;
      }
      if (type === "CNPJ") {
        this.pf = false;
        this.pj = !this.pj;
      }
      this.form.type = type;
    },
  
    newUser(e) {
      e.preventDefault();
      
      const passwordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$*&@#.,/%&!=+-])[0-9a-zA-Z$*&@#.,/%&!=+-]{8,}$/;
      if (
        this.form.password === "" ||
        this.form.password === "" ||
        passwordPattern.test(this.form.password) === false
      ) {
        if (passwordPattern.test(this.form.password) === false) {
          notify('error', ' "Your password must contain at least 8 characters, one lowercase letter, one uppercase letter and one special character"');
         
        } else {
          notify('error',"Sorry, all fields are mandatory.")         
        }
      } else {
        if (this.form.password === this.form.confirmPassword) {
          if (this.termos == true) {
            this.register();
          } else {
            notify('error', 'Required to sign the terms.')
          }
        } else {
          notify('error', 'Passwords do not match.')
        }
      }
    },
    /* formatData() {
      let data = data.split('/');
      data = data[2] + '-' + data[1] + '-' + data[0];
      data = data.reverse().join('-')      
    }, */
    register() {
      this.requesting = true;     
      var data = new FormData();        
          data.append("email", this.form.email);
          data.append("name", this.form.name);
          data.append("tax_id", this.form.document);
          data.append("tax_type", this.form.type);
          data.append("password", this.form.password);
      data.append("birthday", this.form.birthday.split('/').reverse().join('-'));
          this.axios({
            method: "POST",
            url: this.$API_URL + "/Auth/register",
            data: data,
            headers: { "Content-Type": "multipart/form-data" }
          })
            .then(response => {
              this.requesting = false;
              notify('success', `${response.data.DATA}`);            
              this.$router.push({ name: "Login" });
            })
            .catch(err => {
              this.requesting = false;
              if (err && err.response && err.response.data)
                notify('error', `${err.response.data.DATA}`)           
                
              else notify('error',"INVALID_ERROR");
            })
        
        .catch(err => {
          console.log(err)
          this.requesting = false;
        });
    }
  }
};
</script>

<style scoped>
.painel {
  margin-top: 90px;
  background: #292929 0% 0% no-repeat padding-box !important;
  width: 100%;
  height: 100%;
}

.content {
  display: flex;
  justify-content: center;
}

form {
  margin-top: 50px;
  background: #161616 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 15px #0000001a;
  border-radius: 16px;
  min-width: 528px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  margin-bottom: 30px;
}

.painel-title {
  text-align: left;
  font-size: 32px;
  font-family: Inter;
  font-weight: bold;
  letter-spacing: 0px;
  color: #efefef;
}

.painel-text {
  text-align: left;
  font-size: 16px;
  font-family: Inter;
  font-weight: medium;
  letter-spacing: 0px;
  color: #efefef;
  margin-top: 15px;
  margin-bottom: 15px;
}

.sub-title {
  font-size: 16px;
  font-family: Inter;
  font-weight: medium;
  letter-spacing: 0px;
  color: #efefef;
  margin-bottom: 15px;
}

.account-type {
  margin-bottom: 15px;
}

.input {
  height: 30px;
  background: #292929 0% 0% no-repeat padding-box;
  border: 2px solid #646464;
  border-radius: 8px;
}

/* input {
  max-width: 348px !important;
} */

.submit-line {
  position: relative;
  max-width: 100%;
  margin-bottom: 30px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

#inputs {
  padding-left: 8px;
  max-width: 100%;
  text-align: left;
  color: white;
  font-size: 14px;
  font-weight: 500;
  font-family: Inter;
  letter-spacing: 0px;
}

.submit-lente {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  border: none;
  background: transparent;
  outline: none;
  margin-top: 4px;
}

.real {
  margin-right: 4px;
}

.usdi {
  margin-right: 6px;
}

.submit-mascara {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  border: none;
  background: transparent;
  outline: none;
  margin-top: 8px;
  margin-left: 10px;
  font-weight: bold;
  font-size: 16px;
  font-weight: 500;
  font-family: Inter;
  text-align: left;
  color: #9b9b9b;
}

label {
  text-align: left;
  font-family: Inter;
  font-weight: medium;
  font-size: 14px;
  letter-spacing: 0px;
  color: #ffffff;
}

.recovery {
  text-align: center;
  margin-bottom: 15px;
}

.yello-ancora {
  font-family: Inter;
  font-weight: bold;
  font-size: 14px;
  color: #efef18;
}

.termos {
  text-align: center;
  /* max-width: 74px; */
}

.input-group {
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
  flex-wrap: wrap;
}

.btn-cadastro {
  margin-top: 15px;
  width: 288px;
  height: 32px;
  border: 2px solid #efef18;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  font-family: Inter;
  letter-spacing: 0px;
  color: #efef18;
  /*   */
  transition: 0.5s;
  margin-right: auto;
  margin-left: auto;
}

.btn-cadastro:hover {
  background: #efef18;
  color: #161616;
}

/* radio personalizado */
.custom-radio input {
  display: none;
}
.custom-radio input + label:before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: white;
  border: 1px solid grey;
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  margin-bottom: 3px;
  border: 5px solid #efef1800;
}

.custom-radio input:checked + label:before {
width: 18px;
height: 18px;
background: black;
box-sizing: border-box;
border: 5px solid #efef18;
padding: 4px;
}

.radio-group{
  display: flex;
  column-gap: 30px;
}

.invalid-border {
  border: 2px solid red;
}
@media (max-width: 500px) {
  input {
    width: 100% !important;
  }

  form {
    min-width: 0px !important;
  }

  .input-group {
    display: inline;
  }
}
@media (max-width: 440px) {
 /*  form {
    min-width: 100% !important;
  } */
}
</style>
