var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"about"}},[_c('div',{staticClass:"cards"},[_c('div',{staticClass:"card"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"valores"},[_c('div',{staticClass:"valor"},[_vm._v("$ "+_vm._s(_vm.dataPrices.USD.last))]),_vm._m(2)])]),_vm._m(3),_vm._m(4)]),_vm._m(5),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"moeda"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../../../assets/icons/BTC.svg"),"alt":""}})]),_c('div',{staticClass:"type"},[_c('div',{staticClass:"nome"},[_vm._v("BTC")]),_c('div',{staticClass:"abreviacao"},[_vm._v("BTC")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grafico"},[_c('img',{attrs:{"src":require("../../../assets/negativo.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"taxa"},[_c('div',{staticClass:"porcentagem"},[_vm._v("−14,10%")]),_c('div',{staticClass:"hora"},[_vm._v("24h")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"moeda"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../../../assets/Tether-USDT.png"),"width":"40","alt":""}})]),_c('div',{staticClass:"type"},[_c('div',{staticClass:"nome"},[_vm._v("USDT")]),_c('div',{staticClass:"abreviacao"},[_vm._v("USDT")])])]),_c('div',{staticClass:"grafico"},[_c('img',{attrs:{"src":require("../../../assets/positivo.png"),"alt":""}})]),_c('div',{staticClass:"valores"},[_c('div',{staticClass:"valor"},[_vm._v("$ 1,004")]),_c('div',{staticClass:"taxa"},[_c('div',{staticClass:"porcentagem"},[_vm._v("+0,3%")]),_c('div',{staticClass:"hora"},[_vm._v("24h")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"moeda"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../../../assets/Icon-USDC.png"),"width":"40","alt":""}})]),_c('div',{staticClass:"type"},[_c('div',{staticClass:"nome"},[_vm._v("USDC")]),_c('div',{staticClass:"abreviacao"},[_vm._v("USDC")])])]),_c('div',{staticClass:"grafico"},[_c('img',{attrs:{"src":require("../../../assets/negativo.png"),"alt":""}})]),_c('div',{staticClass:"valores"},[_c('div',{staticClass:"valor"},[_vm._v("$ 1,0")]),_c('div',{staticClass:"taxa"},[_c('div',{staticClass:"porcentagem"},[_vm._v("-1,9%")]),_c('div',{staticClass:"hora"},[_vm._v("24h")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container desktop"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"title"},[_vm._v(" About Us ")]),_c('div',{staticClass:"text",staticStyle:{"word-break":"break-all"}},[_vm._v(" USDI is a cryptocurrency exchange platform that allows users to buy, sell, and trade cryptocurrencies such as Bitcoin, Ethereum, Litecoin, and many more. The company was founded in 2022 in British Virgin Islands. USD International offers a user-friendly interface, competitive trading fees, and high liquidity, making it a popular choice among cryptocurrency traders and investors. The platform also provides advanced trading tools, security features, and a mobile app for convenient trading on-the-go ")]),_c('div',{staticClass:"btn-create-account"},[_c('img',{staticStyle:{"color":"#000000 !important"},attrs:{"src":require("../../../assets/Retângulo 1593.png"),"alt":""}})])]),_c('div',{staticClass:"image"},[_c('img',{attrs:{"src":require("../../../assets/sobre.png"),"alt":"Homem segurando um celular, acessando o painel do nosso site"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container mobile"},[_c('div',{staticClass:"image"},[_c('img',{attrs:{"src":require("../../../assets/sobre.png"),"alt":"Homem segurando um celular, acessando o painel do nosso site"}})]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"title"},[_vm._v(" About Us ")]),_c('div',{staticClass:"text",staticStyle:{"word-break":"break-all"}},[_vm._v(" USDI is a cryptocurrency exchange platform that allows users to buy, sell, and trade cryptocurrencies such as Bitcoin, Ethereum, Litecoin, and many more. The company was founded in 2022 in British Virgin Islands. USD International offers a user-friendly interface, competitive trading fees, and high liquidity, making it a popular choice among cryptocurrency traders and investors. The platform also provides advanced trading tools, security features, and a mobile app for convenient trading on-the-go ")]),_c('div',{staticClass:"btn-create-account"},[_c('img',{staticStyle:{"color":"#000000 !important"},attrs:{"src":require("../../../assets/Retângulo 1593.png"),"alt":""}})])])])
}]

export { render, staticRenderFns }