<template>
  <div id="footer">

    <div class="contet">
      <h1 style="text-align: center;" class="logo">
        <img width="157" height="64" src="../../assets/logo.svg" alt="Logo USDI">
      </h1>
      <div class="details">
        <div class="copyright">
          Copyright © 2022 USDI. All rights reserved.
          
        </div>
        <div class="company">
          USDI INTERNATIONAL TECHNOLOGY CORPORATION 
        </div>
        <div class="address">
          BRITSH VIRGIN ISLANDS
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
#footer {
  width: 100%;
  height: 151px;
  background: #161616 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 15px #0000001A;
}



.contet {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
}

.details {
  text-align: left;
  font-size: 16px;
  font-family: Inter;
  font-weight: normal;
  color: #EBEBEB;
  word-break: break-word;
}

@media (max-width: 440px) {
  #footer {
    height: 230px;
  }
  .contet{
    padding-left: 30px;
      padding-right: 30px;
  }
}
</style>